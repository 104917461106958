import React, { useRef, useEffect } from "react";

interface Props {
  image: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function ImageViewer({ open, setOpen, image }: Props) {
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        divRef.current &&
        event.target instanceof Node &&
        !divRef.current.contains(event.target)
      ) {
        setOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [divRef, setOpen]);

  return (
    <div
      className={`fixed top-0 left-0 w-full h-[100vh] z-[1002] 
    ${open ? "show-window" : "hide-window"}
      `}
    >
      <div className="abolsute w-full min-h-[100vh] z-[900] backdrop-blur-[1.5px] bg-[#e2e2e233]"></div>
      <div
        ref={divRef}
        className={`members-handle blur-bg z-[999] overflow-hidden overflow-y-auto fixed bottom-0 md:top-[50%] md:left-[50%] w-full h-[80%] md:w-[45%] md:h-[60%] md:max-w-[475px] md:max-h-[450px] md:translate-x-[-50%] md:translate-y-[-50%] flex items-center justify-center bg-white custom-shadow rounded-[5px]`}
      >
        <div className="for-members-handle overflow-hidden relative flex items-center justify-start flex-col w-full h-full">
          <img src={image} alt="" className="w-full h-full object-cover" />
        </div>
      </div>
    </div>
  );
}

export default ImageViewer;
