import React, { useEffect, useState } from "react";

function BoxLoad() {
  return (
    <div className="task relative z-[auto] flex items-center justify-center w-full h-auto rounded-[6px] mt-[7.5px] border-[1px] border-[#dadae0] bg-[#F6F6F6]">
      <div className="for-task flex items-start justify-start flex-col w-[92.5%] h-auto py-[6px]">
        {/* NAME */}
        <div className="task-name flex items-start justify-between w-full h-auto">
          <div className="flex items-start justify-start flex-col w-auto h-auto">
            <label className="text-[14px] font-[600] text-black">
              <div className="h-[20px] bg-gray-200 w-[150px] animate-pulse rounded-[8px]"></div>
            </label>
            <small className="text-[13px] text-gray-500 font-[400] mt-[3px] flex items-center justify-start">
              <div className="h-[11px] bg-gray-200 w-[140px] animate-pulse rounded-[8px] mr-[8px]"></div>
            </small>
          </div>

          <div className="task-name-more-option z-[2] flex items-center justify-center hover:cursor-pointer"></div>
        </div>
        {/* DESCRIPTION */}
        <div className="task-description flex items-start justify-start w-auto h-auto mt-[6px]">
          <p className="text-[12.5px] text-gray-500 font-[400]">
            <div className="h-[30px] bg-gray-200 w-[50%] animate-pulse rounded-[8px]"></div>
          </p>
        </div>

        {/* COMPLETED */}
        <div className="task-completion animate-pulse flex items-center justify-center w-full h-auto py-[0.5em] mt-[6px] rounded-[5px] border-[1px] border-[#dadae0] bg-white">
          <div className="flex items-center justify-start flex-col w-[95%]">
            <div className="task-completion-head flex items-center justify-between w-full h-auto">
              <div className="flex items-center justify-start w-auto h-auto"></div>

              <label className="text-[12.5px] font-[400] text-[#333]">
                <div className="h-[11px] bg-gray-200 w-[100px] animate-pulse rounded-[8px]"></div>
              </label>
            </div>

            <div className="progress-bar relative z-[1] flex items-center justify-start mt-[6px] w-full h-[6px] rounded-[30px] bg-[#e4e4e4]">
              <div
                className={`fill-bar- absolute h-full rounded-[30px] bg-gray-400 animate-pulse`}
              ></div>
            </div>
          </div>
        </div>

        {/* SEPARATOR */}
        <div className="w-full border-t-[1px] border-[#dadae0] my-[7.5px]"></div>

        {/* MEMBERS */}
        <div className="task-members mt-[6px] flex items-center justify-start w-full h-auto">
          <div className="h-[11px] bg-gray-200 w-[100px] animate-pulse rounded-[8px]"></div>
        </div>
      </div>
    </div>
  );
}

export default BoxLoad;
