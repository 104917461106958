import "./index.css";
import App from "./App";
// import ReactDOM from "react-dom"; Obsolete
import Login from "./Auth/Login";
import { createRoot } from "react-dom/client";
import Project from "./Projects/Components/Project";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <Router>
    <Routes>
      {/* <Route
        path="*"
        element={
          <p>
            Un probleme est survenu. Veuillez contacter l'administrateur du
            site.
          </p>
        }
      /> */}
      <Route path="/" element={<App />} />

      <Route path="/teams" element={<App />} />
      <Route path="/auth" element={<Login />} />
      <Route path="/projects" element={<App />} />
      <Route path="/business" element={<App />} />
      <Route path="/dashboard" element={<App />} />
      <Route path="/settings" element={<App />} />

      <Route path="/project/:id" element={<Project />} />
    </Routes>
  </Router>
);
