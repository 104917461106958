import React, { useState, useEffect } from "react";
import Task from "./Task";
import "../../index.css";
import MoreForSection from "./MoreForSection";
import { DocumentData } from "firebase/firestore";
import {
  TaskType,
  toDo,
  doing,
  finished,
} from "../../Interfaces/TaskInterface";
import CreateTask from "./CreateTask";
import { v4 as uuid } from "uuid";

interface Props {
  name: string;
  admin: boolean;
  searchTask?: string;
  project: DocumentData;
  tasks: DocumentData[];
  filteredTasks?: DocumentData[];
}

function TaskSection({
  name,
  tasks,
  project,
  admin,
  filteredTasks,
  searchTask,
}: Props) {
  const [color, setColor] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [sectionName, setSectionname] = useState<string>("");

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [open]);

  useEffect(() => {
    if (name === "toDo") setColor(toDo);
    if (name === "doing") setColor(doing);
    if (name === "finished") setColor(finished);

    const popupElement = document.querySelector(
      `.task-section-name-popup-${name}`
    ) as HTMLElement;
    if (popupElement) {
      popupElement.style.backgroundColor = color;
    }

    if (name === "toDo") setSectionname("A faire");
    if (name === "doing") setSectionname("En cours");
    if (name === "finished") setSectionname("Terminées");
  }, [color, name]);

  return (
    <div className="task-section flex items-center justify-center h-auto snap-center min-w-[97.5%] md:min-w-0 md:w-[24%] mx-[0.5em] md:mr-[1em]">
      <CreateTask
        project={project}
        status={name}
        open={open}
        setOpen={setOpen}
      />
      <div className="for-task-section flex items-start justify-start flex-col w-full h-auto">
        {/* NAME */}
        <div className="task-section-name custom-shadow top-0 z-auto rounded-[8px] border-[#dadae0] bg-[#F6F6F6] flex items-center justify-center w-full h-[40px]">
          <div className="flex items-center justify-between w-[92.5%] h-auto">
            <div className="flex items-center justify-start w-auto h-auto">
              <label className="text-[15px] font-[500] text-[#333]">
                {sectionName}
              </label>
              <div
                className={`task-section-name-popup-${name} flex items-center p-0 justify-center ml-[8px] w-[20px] h-[20px] rounded-[50%] bg-[${color}]`}
              >
                <label className="text-[13px] m-0 text-white">
                  {tasks.length}
                </label>
              </div>
            </div>

            <div className="flex items-center justify-center hover:cursor-pointer z-auto">
              {admin && (
                <MoreForSection project={project} tasks={tasks} status={name} />
              )}
            </div>
          </div>
        </div>
        {/* BUTTON */}
        <div className="create-new-task flex items-center justify-center w-full h-auto mt-[7.5px]">
          <button
            disabled={!admin}
            onClick={(e) => setOpen(true)}
            className="text-[#333] flex items-center justify-center w-[100%] h-[40px] rounded-[8px] border-[1px] border-dashed border-[#dadae0] bg-[#F6F6F6]"
          >
            <label className="text-[14px] disabled:cursor-none font-[500] mr-[7px] hover:cursor-pointer">
              Ajouter une tâche
            </label>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-[15px] h-[15px]"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 6v12m6-6H6"
              />
            </svg>
          </button>
        </div>

        <section className="all-tasks flex items-center justify-start flex-col w-full h-auto mt-[7.5px]">
          {tasks.length === 0 &&
            filteredTasks &&
            filteredTasks.length === 0 && (
              <label className="text-[14px] text-gray-500 my-[1em]">
                Aucune tâche.
              </label>
            )}
          {filteredTasks === null ||
            (filteredTasks &&
              filteredTasks.length === 0 &&
              searchTask === "" &&
              tasks.map((task) => (
                <Task
                  admin={admin}
                  task={task}
                  key={task.id}
                  project={project}
                  color={color}
                  status={name}
                />
              )))}

          {filteredTasks &&
            filteredTasks.length > 0 &&
            filteredTasks.map((task) => (
              <Task
                admin={admin}
                task={task}
                key={task.id}
                project={project}
                color={color}
                status={name}
              />
            ))}
        </section>
      </div>
    </div>
  );
}

export default TaskSection;
