import React, { useState, useEffect } from "react";
import { v4 as uuid } from "uuid";
import "../../index.css";
import Popup from "../../Components/Popup";
import Loader from "../../Components/Loader";
import { createTeam, modifyTeam } from "../../Backend/Team/HandleTeam";

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  id?: string;
  nameM?: string;
  descriptionM?: string;
  locationM?: string;
  linkM?: string;
  modify?: boolean;
}

type popup = {
  text: string;
  state: string;
};

function CreateTeam({
  open,
  setOpen,
  id,
  nameM,
  descriptionM,
  locationM,
  linkM,
  modify,
}: Props) {
  const [popup, setPopup] = useState<popup>({
    state: "success",
    text: "Invalid error",
  });
  const [link, setLink] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [description, setDescription] = useState<string>("");

  useEffect(() => {
    if (
      nameM !== undefined &&
      descriptionM !== undefined &&
      locationM !== undefined &&
      id !== undefined &&
      linkM !== undefined
    ) {
      setName(nameM);
      setDescription(descriptionM);
      setLocation(locationM);
      setLink(linkM);
    }
  }, [descriptionM, id, linkM, locationM, nameM, open]);

  const copyLink = () => {
    navigator.clipboard.writeText(link);
    setPopup({
      state: "success",
      text: "Lien copié !",
    });
    setIsVisible(true);
  };

  useEffect(() => {
    setLink(`invite.io/${uuid().substring(0, 5).toUpperCase()}`);

    if (!open) {
      setName("");
      setDescription("");
      setLocation("");
      setLink("");
    }
  }, [open]);

  const fill = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    let target = e.currentTarget;

    if (target.name === "name") {
      setName(target.value);
    } else if (target.name === "description") {
      setDescription(target.value);
    } else if (target.name === "location") {
      setLocation(target.value);
    } else if (target.name === "link") {
      setLink(target.value);
    }
  };

  const saveTeam = () => {
    if (name === "" || location === "") {
      setPopup({
        state: "error",
        text: "Remplissez correctement les champs.",
      });

      setIsVisible(true);
      return;
    }

    if (modify) {
      const result = window.confirm(
        `Voulez-vous vraiment modifier l'équipe ${name}?`
      );

      if (result) {
        modifyTeam(
          id,
          name,
          description,
          location,
          setPopup,
          setIsVisible,
          setIsLoading,
          setOpen
        );
      }
      return;
    }

    const result = window.confirm(
      `Voulez-vous vraiment créer la l'équipe ${name}?`
    );

    if (result) {
      createTeam(
        name,
        description,
        location,
        link,
        setIsLoading,
        setPopup,
        setIsVisible,
        setOpen
      );
    }
  };

  return (
    <div
      className={`w-full min-h-[100vh] ${
        open ? "show-window" : "hide-window"
      } fixed top-0 left-0 z-[1002]`}
    >
      <Popup isVisible={isVisible} infos={popup} />
      <div className="fixed w-full h-[100vh] backdrop-blur-[1.5px] bg-[#e2e2e233]"></div>
      <div className="create-team pt-[0.5em] z-[1001] pb-[70px] overflow-hidden overflow-y-auto fixed bottom-0 md:top-[50%] md:left-[50%] w-full min-w-full md:min-w-0 h-[75%] md:w-[45%] max-w-[400px] md:h-[90%] md:max-h-[600px] md:translate-x-[-50%] md:translate-y-[-50%] flex items-center justify-center bg-white custom-shadow rounded-[12px] ">
        <div className="bottom-buttons fixed flex items-center justify-evenly bg-gray-50 border-t-[1px] border-gray-300 bottom-0 w-full h-[60px] py-[10px]">
          <button
            onClick={(e) => setOpen(false)}
            className="flex items-center justify-center bg-white hover:cursor-pointer w-[47.5%] h-[42.5px] rounded-[8px] border-[1px] border-gray-300 text-[#333] bg-transparent"
          >
            <label className="hover:cursor-pointer">Annuler</label>
          </button>
          <button
            onClick={saveTeam}
            className="flex items-center justify-center hover:cursor-pointer w-[47.5%] h-[42.5px] rounded-[8px] border-[1px] border-blue-500 bg-blue-600 text-white"
          >
            {isLoading ? (
              <Loader />
            ) : (
              <label className="hover:cursor-pointer">
                {modify ? "Modifier l'équipe" : "Créer l'équipe"}
              </label>
            )}
          </button>
        </div>
        <div className="for--create-team relative overflow-y-auto z-0 flex items-center justify-start flex-col w-full h-full">
          {/* TOP */}
          <div className="create-team-top flex items-center justify-center w-full h-auto py-[10px]">
            <div className="for--create-team-top flex items-center justify-between w-[90%] h-auto">
              <div className="create-team-txt flex items-start justify-start flex-col">
                <label className="text-black text-[20px] font-[500]">
                  {modify ? "Modifier l'" : "Créer une "}équipe
                </label>
                <p className="text-[14px] text-gray-400 font-[400] mt-[8px]">
                  Une equipe peut vous aider à selectionner rapidement un lot de
                  personnes pour votre projet.
                </p>
              </div>

              <div className="create-team-icon flex items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 -960 960 960"
                  className="w-[40px] h-[40px]"
                >
                  <path d="M48-264v-57.057Q48-360 87-384t105-24q14 0 26 1t23 2.739Q229-386 222.5-365q-6.5 21-6.5 43.222V-264H48Zm216 0v-57.629Q264-350 278.5-372q14.5-22 43.5-39t69-25q40-8 89.029-8 49.971 0 89.471 8 39.5 8 68.5 24.5t43.5 39Q696-350 696-321.629V-264H264Zm480 0v-58q0-22-6.5-42.5T719-404q9-2 20.5-3t28.5-1q66 0 105 23.85t39 63.45v56.7H744ZM191.788-456Q162-456 141-477.026q-21-21.027-21-50.553Q120-558 141.026-579q21.027-21 50.553-21Q222-600 243-579.239t21 51.451Q264-498 243.239-477q-20.761 21-51.451 21Zm576 0Q738-456 717-477.026q-21-21.027-21-50.553Q696-558 717.026-579q21.027-21 50.553-21Q798-600 819-579.239t21 51.451Q840-498 819.239-477q-20.761 21-51.451 21Zm-288.234-36Q435-492 403.5-523.5T372-600q0-45 31.5-76.5T480-708q45 0 76.5 31.5t31.5 76.946Q588-555 556.5-523.5T479.554-492Z" />
                </svg>
              </div>
            </div>
          </div>
          {/* INFOS */}
          <div className="create-team-infos mt-[1.25em] flex items-start justify-start flex-col w-[90%] h-auto">
            <div className="common-for-infos flex items-start justify-start flex-col w-full h-auto mb-[1.15em]">
              <label className="text-[13px] font-[400]">Nom de la team</label>
              <div className="field mt-[5px] w-full h-[37.5px] px-[8px] border-[1.5px] border-[#F4F3F8] rounded-[6px] overflow-hidden">
                <input
                  value={name}
                  onChange={(e) => fill(e)}
                  name="name"
                  type="text"
                  placeholder="Equipe de maintenance"
                  className="w-[calc(100%-16px)] text-[16px] placeholder:text-[14px] h-full outline-none border-none"
                />
              </div>
            </div>
            <div className="common-for-infos flex items-start justify-start flex-col w-full h-auto mb-[1.15em]">
              <label className="text-[13px] font-[400]">
                Description{" "}
                <span className="text-[10px] text-gray-400">(optionnel)</span>
              </label>
              {!modify && (
                <div className="field mt-[5px] w-full h-[37.5px] px-[8px] border-[1.5px] border-[#F4F3F8] rounded-[6px] overflow-hidden">
                  <input
                    value={description}
                    onChange={(e) => fill(e)}
                    type="text"
                    name="description"
                    placeholder="Maintenance informatique, DevOps, CM."
                    className="w-[calc(100%-16px)] text-[16px] placeholder:text-[14px] h-full outline-none border-none"
                  />
                </div>
              )}

              {modify && (
                <div className="field pt-[5px] mt-[5px] w-full h-auto px-[8px] border-[1.5px] border-[#F4F3F8] rounded-[6px] overflow-hidden">
                  <textarea
                    value={description}
                    onChange={(e) => fill(e)}
                    name="description"
                    placeholder="Verifier si tout marche."
                    className="w-full text-[16px] placeholder:text-[14px] h-[55px] min-h-[55px] resize-y outline-none border-none"
                  />
                </div>
              )}
            </div>
            <div className="common-for-infos flex items-start justify-start flex-col w-full h-auto mb-[1.15em]">
              <label className="text-[13px] font-[400]">Localisation</label>
              <div className="field mt-[5px] w-full h-[37.5px] px-[8px] border-[1.5px] rounded-[6px]border-[1.5px] border-[#F4F3F8] rounded-[6px] overflow-hidden">
                <input
                  value={location}
                  onChange={(e) => fill(e)}
                  name="location"
                  type="text"
                  placeholder="Tampouy, Ouagadougou, Burkina Faso"
                  className="w-[calc(100%-16px)] text-[16px] placeholder:text-[14px] h-full outline-none border-none"
                />
              </div>
            </div>

            {/* <div className="generate-link w-full h-auto flex items-start justify-start flex-col mt-[0.25em]">
              <div className="generate-link-value flex items-center justify-between w-full h-auto">
                <div className="link-icon flex items-center justify-center w-[40px] h-[40px] rounded-[50%] bg-blue-100">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-link-45deg w-[27px] h-[27px] text-blue-600"
                    viewBox="0 0 16 16"
                  >
                    <path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.002 1.002 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z" />
                    <path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243L6.586 4.672z" />
                  </svg>
                </div>

                <div className="link-value w-[calc(100%-50px)] px-[9px] h-[37.5px] flex items-center justify-start relative border-[1.5px] border-[#F4F3F8] rounded-[6px]">
                  <label className="text-[14px] text-[#333] font-[400]">
                    {linkM !== undefined ? linkM : link}
                  </label>

                  <button
                    onClick={copyLink}
                    className="copy-link hover:cursor-pointer absolute right-[12px] flex items-center justify-center rounded-[4px] p-[3px] bg-[#F4F5F7] border-[1.5px] border-[#F4F3F8]"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-clipboard w-[10px] h-[10px]"
                      viewBox="0 0 16 16"
                    >
                      <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                      <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                    </svg>
                    <label className="text-[12px] text-gray-400 font-[400] ml-[6px] hover:cursor-pointer">
                      Copier
                    </label>
                  </button>
                </div>
              </div>

              <p className="text-[13px] text-red-500 font-[400] mt-[8px]">
                Le lien sera utilisable une fois que l'équipe sera créer.
              </p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateTeam;
