import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

function HeaderLoad() {
  return (
    <div className="project-head relative flex items-center justify-center flex-col w-full h-auto my-[1.25em]">
      {/* <ProjectStats /> */}
      <div className="for--project-head flex items-center justify-start flex-col w-full h-auto">
        {/* INFOS */}
        <div className="project-head-infos z-10 flex items-center justify-center flex-col w-[92.5%] h-auto">
          <div className="for--project-head-infos flex md:items-end md:justify-between w-full h-auto">
            {/* TXT */}

            <div className="project-head-infos-txt flex items-start justify-start flex-col w-auto h-auto">
              {/* NAME */}
              <div className="project-name-actions-date flex items-start justify-start flex-col w-auto h-auto">
                <div className="project-name-action flex items-center justify-start w-auto">
                  <div className="project-name text-[25px] text-black font-[500]">
                    <div className="h-[27px] bg-gray-200 w-[150px] animate-pulse rounded-[8px]"></div>
                  </div>

                  <div className="project-actions flex items-center justify-start ml-[15px]"></div>
                </div>

                <small className="text-[13px] text-gray-500 font-[400] mt-[3px] flex items-center justify-start">
                  <div className="h-[11px] bg-gray-200 w-[140px] animate-pulse rounded-[8px] mr-[8px]"></div>
                  <div className="h-[11px] bg-gray-200 w-[100px] animate-pulse rounded-[8px]"></div>
                </small>
              </div>

              <div className="common-for-team-features mt-[1em] flex items-center justify-start w-auto h-auto m-[8px] ml-[0px]">
                <label className="team-feature-label text-[15px] text-gray-600 font-[400]">
                  <div className="h-[30px] bg-gray-200 w-[120px] animate-pulse rounded-[8px]"></div>
                </label>
              </div>

              {/* FEATURES */}

              <div className="project-feature flex-wrap flex items-center justify-start max-w-[400px] w-auto h-auto">
                <div className="common-for-team-features flex items-center justify-start w-auto h-auto m-[8px] ml-[0px]">
                  <div className="team-feature-svg bg-gray-200 rounded-[8px] w-[20px] h-[20px] animate-pulse flex items-center justify-center mr-[8px]"></div>
                  <label className="team-feature-label text-[15px] text-gray-600 font-[400]">
                    <div className="h-[22px] bg-gray-200 w-[120px] animate-pulse rounded-[8px]"></div>
                  </label>
                </div>
                <div className="common-for-team-features flex items-center justify-start w-auto h-auto m-[8px] ml-[0px]">
                  <div className="team-feature-svg bg-gray-200 rounded-[8px] w-[20px] h-[20px] animate-pulse flex items-center justify-center mr-[8px]"></div>
                  <label className="team-feature-label text-[15px] text-gray-600 font-[400]">
                    <div className="h-[22px] bg-gray-200 w-[120px] animate-pulse rounded-[8px]"></div>
                  </label>
                </div>
                <div className="common-for-team-features flex items-center justify-start w-auto h-auto m-[8px] ml-[0px]">
                  <div className="team-feature-svg bg-gray-200 rounded-[8px] w-[20px] h-[20px] animate-pulse flex items-center justify-center mr-[8px]"></div>
                  <label className="team-feature-label text-[15px] text-gray-600 font-[400]">
                    <div className="h-[22px] bg-gray-200 w-[120px] animate-pulse rounded-[8px]"></div>
                  </label>
                </div>
                <div className="common-for-team-features flex items-center justify-start w-auto h-auto m-[8px] ml-[0px]">
                  <div className="team-feature-svg bg-gray-200 rounded-[8px] w-[20px] h-[20px] animate-pulse flex items-center justify-center mr-[8px]"></div>
                  <label className="team-feature-label text-[15px] text-gray-600 font-[400]">
                    <div className="h-[22px] bg-gray-200 w-[120px] animate-pulse rounded-[8px]"></div>
                  </label>
                </div>
              </div>
            </div>

            {/* MEMBERS */}

            <div className="project-handle-members flex items-center justify-center w-auto h-auto">
              <div className="for-project-handle-members flex items-start justify-start flex-col w-full h-auto">
                <label className="text-[13px] text-gray-400 font-[400] mb-[8px]">
                  <div className="h-[11px] bg-gray-200 w-[90px] animate-pulse rounded-[8px] mr-[8px]"></div>
                </label>

                <div className="handle-members-action flex items-center justify-start w-auto h-auto">
                  <div className="members-list flex items-center justify-center w-auto h-auto">
                    <div className="h-[35px] bg-gray-200 w-[130px] animate-pulse rounded-[8px]"></div>
                  </div>

                  <button className="flex items-center animate-pulse justify-center w-[35px] h-[35px] px-[0.5em] ml-[7px] rounded-[8px] bg-teal-600 text-white"></button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* ACTIONS */}

        <div className="project-head-actions border-[1.5px] border-[#EEEEF3] py-[6.5px] w-full h-auto flex items-center justify-center mt-[1em]">
          <div className="for-project-head-actions flex items-start justify-between w-[92.5%] h-auto">
            {/* SEARCH */}
            <div className="project-head-action-search-bar flex items-center justify-start w-[75%] h-auto">
              <div className="search-bar border-[1.5px] animate-pulse border-[#dadae0] flex items-center justify-start w-full h-[40px] bg-[#F6F6F6] rounded-[8px] overflow-hidden"></div>
            </div>

            {/* ACTIONS */}
            <div className="project-head-action-actions">
              <div className="for--project-head-action-actions">
                <button className="flex items-center animate-pulse justify-center w-[35px] h-[35px] px-[0.5em] ml-[7px] rounded-[8px] bg-teal-600 text-white"></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderLoad;
