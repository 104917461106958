import React, { useState, useEffect } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { RecaptchaVerifier } from "firebase/auth";
import Input from "./Components/Input";
import Select from "./Components/Select";
import Picture from "./Components/Picture";
import Loader from "../Components/Loader";
import Popup from "../Components/Popup";
import { PhoneNumberFormat, PhoneNumberUtil } from "google-libphonenumber";
import { ConfirmationResult } from "firebase/auth";
import {
  verifyAccount,
  phoneCredential,
  verifyOrganisation,
  sendVerificationCode,
} from "../Backend/Auth/Authentification";
import "../Style/Login.css";
import "../index.css";
import Image from "./Image.png";
import Video from "./video.mp4";
import { useNavigate } from "react-router-dom";
import { app } from "../Backend/Firebase/databaseConfig";

export type country = {
  id: string;
  name: string;
  code: string;
  flag: string;
};

type popup = {
  text: string;
  state: string;
};

const actvities = [
  "Sécurité incendiaire",
  "Sécurité électronique",
  "Surveillance vidéo",
  "Cybersécurité",
  "Sécurité des transports",
  "Sécurité privée",
  "Transport de fonds",
  "Technologie de l'information (TI) et Services informatiques",
  "Santé et Services médicaux",
  "Finance et Services financiers",
  "Éducation et Formation",
  "Commerce de détail et Commerce électronique",
  "Industrie alimentaire et Restauration",
  "Immobilier et Gestion immobilière",
  "Ingénierie et Architecture",
  "Tourisme et Voyage",
  "Énergie et Environnement",
  "Automobile",
  "Aérospatiale et Défense",
  "Mode et Vêtements",
  "Médias et Divertissement",
  "Télécommunications",
  "Chimie",
  "Agriculture",
  "Art et Culture",
  "Logistique et Chaîne d'approvisionnement",
  "Services juridiques",
  "Secteur public",
  "Autre",
];

const sizes = [
  "Auto-entrepreneur",
  "Petite entreprise (1-49)",
  "Moyenne entreprise (50-249)",
  "Grande entreprise (250 et plus)",
];

const Login = () => {
  const navigate = useNavigate();
  const firebaseAuth = getAuth(app);

  const [popup, setPopup] = useState<popup>({
    state: "success",
    text: "Invalid error",
  });
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [number, setNumber] = useState<string>("");
  const [choice, setChoice] = useState<string>("");
  const [picture, setPicture] = useState<string>("");
  const [nickname, setNickname] = useState<string>("");
  const [find, setFind] = useState<string>("no-search");
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [verificationCode, setVerificationCode] = useState<string>("");
  const [organisationName, setOrganisationName] = useState<string>("");
  const [country, setCountry] = useState<country | undefined>(undefined);
  const [joinOrganisationId, setJoinOrganisationId] = useState<string>("");
  const [organisationLength, setOrganisationLength] = useState<string>("");
  const [organisationPicture, setOrganisationPicture] = useState<string>("");
  const [organisationActivity, setOrganisationActivity] = useState<string>("");
  const [joinOrganisationSecret, setJoinOrganisationSecret] =
    useState<string>("");
  const [confirmationResults, setConfirmationsResults] =
    useState<ConfirmationResult | null>(null);
  const [, setRecaptcha] = useState<RecaptchaVerifier>();

  useEffect(() => {
    onAuthStateChanged(firebaseAuth, (user) => {
      if (user) {
        navigate("/");
      }
    });
  }, [firebaseAuth, navigate]);

  useEffect(() => {
    if (state === "create-acc") {
      swipe("", "next", "auth-step", "create-acc");
    }
  }, [state]);

  useEffect(() => {
    if (find === "finded") {
      swipe("", "next", "join-org", "conf-create-org");
    } else if (find === "not-find") {
      setPopup({
        state: "error",
        text: "Aucune organisation trouvée.",
      });
      setIsVisible(true);
    }
  }, [find]);

  useEffect(() => {
    if (confirmationResults != null) {
      swipe("", "next", "conf-create-org", "conf-phone");
    }
  }, [confirmationResults]);

  // useEffect(() => {
  //   const phoneUtil = PhoneNumberUtil.getInstance();
  //   const region = country?.id.toUpperCase();

  //   if (region) {
  //     const phoneNumberObj = phoneUtil.parse(phoneNumber, region);

  //     if (phoneUtil.isValidNumberForRegion(phoneNumberObj, region)) {
  //       const formattedNumber = phoneUtil.format(
  //         phoneNumberObj,
  //         PhoneNumberFormat.E164
  //       );
  //       setNumber(formattedNumber);
  //     }
  //   }
  // }, [country?.id, phoneNumber]);

  // const sendCode = () => {
  //   setNSendCode(1);

  //   // if (recaptcha !== undefined) {
  //   //   recaptcha.clear();

  //   sendVerificationCode(
  //     number,
  //     setConfirmationsResults,
  //     setRecaptcha,
  //     setIsLoading,
  //     setPopup,
  //     setIsVisible,
  //     setNSendCode,
  //     nSendCode
  //   );
  //   // }
  // };

  const isValidPhoneNumber = (numberToVerify: string) => {
    const phoneUtil = PhoneNumberUtil.getInstance();

    const numericOnly = numberToVerify.replace(/\D/g, "");

    if (!/^\d+$/.test(numericOnly)) {
      setPopup({
        state: "error",
        text: "Numéro de téléphone incorrect.",
      });
      setIsVisible(true);
      return false;
    }

    const region = country?.id.toUpperCase();

    try {
      const phoneNumberObj = phoneUtil.parse(numberToVerify, region);

      if (phoneUtil.isValidNumberForRegion(phoneNumberObj, region)) {
        const formattedNumber = phoneUtil.format(
          phoneNumberObj,
          PhoneNumberFormat.E164
        );
        setNumber(formattedNumber);

        if (number === "") {
          setNumber(formattedNumber);
        }

        return true;
      } else {
        setPopup({
          state: "error",
          text: "Numéro de téléphone incorrect pour le pays spécifié.",
        });
        setIsVisible(true);
        return false;
      }
    } catch (e) {
      setPopup({
        state: "error",
        text: "Numéro de téléphone invalide.",
      });
      setIsVisible(true);

      return false;
    }
  };

  const isValidEmail = (email: string) => {
    const pattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
    return pattern.test(email.toLowerCase());
  };

  const verifyNickname = (username: string) => {
    const pattern = /^[a-zA-Z0-9]+[.][a-zA-Z0-9]+$/;
    return pattern.test(username);
  };

  const auth = () => {
    if (country === undefined) {
      setPopup({
        state: "error",
        text: "Choississez un pays.",
      });
      setIsVisible(true);
      return;
    }

    if (!isValidPhoneNumber(phoneNumber)) {
      setPopup({
        state: "error",
        text: "Numéro de téléphone incorrect.",
      });
      setIsVisible(true);
      return;
    }
  };

  useEffect(() => {
    if (number !== "") {
      verifyAccount(number, setIsLoading, setPopup, setIsVisible, setState);
    }
  }, [number]);

  const validateProcess = () => {
    phoneCredential(
      confirmationResults,
      verificationCode,
      setIsLoading,
      setPopup,
      setIsVisible,
      name,
      nickname,
      picture,
      email,
      number,
      joinOrganisationId,
      organisationName,
      organisationActivity,
      organisationLength,
      organisationPicture,
      choice
    );
  };

  const createOrganisation = () => {
    setIsLoading(true);
    if (
      organisationName === "" ||
      organisationActivity === undefined ||
      organisationLength === undefined
    ) {
      setPopup({
        state: "error",
        text: "Remplissez bien les champs requis.",
      });
      setIsVisible(true);
      setIsLoading(false);
      return;
    } else {
      swipe("", "next", "create-org", "add-org-pp");
      setIsLoading(false);
    }
  };

  const confirmCreateOrg = () => {
    sendVerificationCode(
      number,
      setConfirmationsResults,
      setRecaptcha,
      setIsLoading
    );
  };

  const createAcc = () => {
    setIsLoading(true);
    if (!isValidEmail(email)) {
      setPopup({
        state: "error",
        text: "Entrez un mail valide.",
      });
      setIsVisible(true);
      setIsLoading(false);
      return;
    }

    if (!verifyNickname(nickname)) {
      setPopup({
        state: "error",
        text: "Entrez un surnom valide.",
      });
      setIsVisible(true);
      setIsLoading(false);
      return;
    }

    if (name.length < 3) {
      setPopup({
        state: "error",
        text: "Le nom doit comporter au moins 3 caractères.",
      });
      setIsVisible(true);
      setIsLoading(false);
      return;
    }

    setIsLoading(false);
    swipe("", "next", "create-acc", "add-pp");
  };

  const handleOrganisation = () => {
    setFind("no-search");
    if (choice === "") {
      setPopup({
        state: "error",
        text: "Faites un choix.",
      });
      setIsVisible(true);
    } else {
      if (choice === "create") {
        document.querySelector(".join-org")?.classList.remove("flex");
        document.querySelector(".join-org")?.classList.add("hidden");
        swipe("", "next", "select-team", "create-org");
      } else if (choice === "join") {
        swipe("", "next", "select-team", "join-org");
      }
    }
  };

  const tryJoinOrg = () => {
    if (choice === "join") {
      verifyOrganisation(
        joinOrganisationId,
        joinOrganisationSecret,
        setOrganisationName,
        setOrganisationPicture,
        setFind,
        setIsLoading,
        setPopup,
        setIsVisible
      );
    }
  };

  const swipe = (event: any, method: string, curr: string, target: string) => {
    let actual = document.querySelector("." + curr);

    if (method === "prev") {
      if (actual) {
        actual.classList.remove("opacity-[1]");
        actual.classList.add("custom-visible-reverse");
      }

      setTimeout(() => {
        const targetElement = document.querySelector("." + target);
        if (targetElement) {
          targetElement.classList.add("revive");
          targetElement.classList.remove("invisible");
        }
      }, 100);
    } else if (method === "next") {
      if (curr === "join-org") {
        document.querySelector(".create-org")?.classList.add("custom-visible");
        document.querySelector(".add-org-pp")?.classList.add("custom-visible");
      }

      if (actual) {
        actual.classList.remove("revive");
        actual.classList.remove("flex");
        actual.classList.add("invisible");
        actual.classList.remove("custom-visible-reverse");
        actual.classList.add("custom-visible");
      }

      setTimeout(() => {
        const targetElement = document.querySelector("." + target);
        if (targetElement) {
          targetElement.classList.remove("hidden");
          targetElement.classList.remove("invisible");
          targetElement.classList.add("revive");
          targetElement.classList.add("opacity-[1]");
        }
      }, 100);
    }
  };

  const toggle = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    option: string
  ) => {
    setChoice("");
    setChoice(option);
  };

  return (
    <>
      <div className="_login w-full h-[100vh]">
        <div id="recaptcha-container"></div>
        <Popup isVisible={isVisible} infos={popup} />
        {/* VIDEO CONTAINER */}
        <section className="absolute z-[-1] w-full h-screen flex items-center justify-center">
          <div className="for--video-container relative w-full h-full flex items-center justify-center object-cover">
            <img
              loading="eager"
              className="absolute z-[-2] w-full h-full object-cover"
              src={Image}
              alt=""
            />
            <video
              autoPlay
              muted
              loop
              playsInline
              className="video-login w-full h-full object-cover"
            >
              <source src={Video} type="video/mp4" />
              Votre navigateur ne prend pas en charge la lecture de vidéos.
            </video>
          </div>
        </section>
        <section className="gradient-box z-[1] relative w-full h-full pt-[25px] sm:p-[35px] flex items-center justify-center">
          <div className="for-login md:w-[95%] w-[90%] h-full flex items-start justify-start flex-col">
            {/* HEADER */}
            <header>
              <div className="for--login-header">
                <div className="login-header--illustration">
                  <p className="md:text-[25px] sm:text-[22.5px] font-bold text-white">
                    <span className="text-blue-500">TeamMask</span> By
                    Intellifrica
                  </p>
                  <label className="text-[16px] text-gray-500 font-[500]">
                    Gérez des projets facilement
                  </label>
                </div>
              </div>
            </header>
            {/* BODY */}
            <div className="flex overflow-x-hidden w-full h-full items-start justify-start flex-col mt-[9%] sm:mt-[5%] border-white">
              <div className="for--login-body flex items-start justify-start w-full max-w-full h-full">
                {/* COMMON-FOR-SECTION */}
                <section className="common-for-login-step auth-step w-full h-auto border-white next-div flex items-start justify-start flex-col">
                  <label className="section-name self-start text-[26px] text-white font-bold">
                    Entrez vos informations
                    <span className="text-blue-500 text-[35px]">.</span>
                  </label>
                  <label className="text-[14px] text-gray-400 self-start">
                    Des troubles de connexion?{" "}
                    <span className="text-blue-500 hover:underline hover:cursor-pointer">
                      Contactez-nous
                    </span>
                    .
                  </label>
                  <div className="login-section-components next-div w-[100%] max-w-[455px] h-auto mt-[25px]">
                    <div className="for--login-section-components">
                      <Select value={country} setValue={setCountry} />
                      <Input
                        field="num"
                        type="text"
                        value={phoneNumber}
                        isLoading={isLoading}
                        setValue={setPhoneNumber}
                        placeholder="67 48 54 01"
                        fieldName="Numéro de téléphone"
                        disabled={country === undefined}
                      />
                      {/* <Input
                        field="password"
                        type="password"
                        isLoading={isLoading}
                        value={password}
                        setValue={setPassword}
                        fieldName="Mot de passe"
                        placeholder="8 caractères minimum"
                      /> */}
                      <div className="buttons flex items-center justify-between w-full h-auto mt-[35px]">
                        <button
                          disabled={isLoading}
                          onClick={auth}
                          className="hover:opacity-[0.8] duration-[0.4s] flex items-center justify-center w-full h-[50px] text-[17px] text-white bg-blue-500 font-[500] rounded-[50px]"
                        >
                          {isLoading ? <Loader /> : "Authentification"}
                        </button>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="common-for-login-step next-div create-acc opacity-0 w-full h-auto border-white login-step items-start justify-start flex-col hidden">
                  <label className="section-name text-[37px] text-white font-bold">
                    Dites-nous en plus
                    <span className="text-blue-500 text-[35px]">.</span>
                  </label>
                  <label className="text-gray-400">
                    Des troubles de connexion?{" "}
                    <span className="text-blue-500 hover:underline hover:cursor-pointer">
                      Contactez-nous
                    </span>
                    .
                  </label>
                  <div className="login-section-components w-[100%] max-w-[455px] h-auto mt-[25px]">
                    <div className="for--login-section-components">
                      <Input
                        field="name"
                        type="text"
                        isLoading={isLoading}
                        value={name}
                        setValue={setName}
                        fieldName="Prénoms & Nom"
                        placeholder="Thibaud Nikiema"
                      />
                      <Input
                        field="name"
                        type="text"
                        isLoading={isLoading}
                        value={nickname}
                        setValue={setNickname}
                        fieldName="Surnom"
                        placeholder="N.MALICK"
                      />
                      <div className="text-gray-300 mt-[-12px] mb-[15px]">
                        <small>
                          Le surnom doit contenir une lettre minimum à gauche,
                          un "." (point) au milieu et une lettre minimum à
                          droite. (Ex. N.MALICK).
                        </small>
                      </div>
                      <Input
                        field="mail"
                        type="email"
                        isLoading={isLoading}
                        value={email}
                        setValue={setEmail}
                        fieldName="Adresse électronique"
                        placeholder="mohamed.amadou@intellifrica.com"
                      />
                      <div className="buttons flex items-center justify-between w-full h-auto mt-[35px]">
                        {/* <button
                          onClick={(e) =>
                            swipe(e, "prev", "create-acc", "auth-step")
                          }
                          className="md:w-[47.5%] duration-[0.4s] flex items-center justify-center w-[100%] mb-[10px] h-[50px] text-[17px] text-white bg-[#565B68] font-[500] rounded-[50px]"
                        >
                          Précedent
                        </button> */}
                        <button
                          disabled={isLoading}
                          onClick={createAcc}
                          className="hover:opacity-[0.8] duration-[0.4s] flex items-center justify-center w-full h-[50px] text-[17px] text-white bg-blue-500 font-[500] rounded-[50px]"
                        >
                          {isLoading ? <Loader /> : "Suivant"}
                        </button>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="common-for-login-step next-div opacity-0 add-pp w-full h-full border-white login-step items-start justify-start flex-col hidden">
                  <label className="section-name text-[37px] text-white font-bold">
                    Faites-vous reconnaitre
                    <span className="text-blue-500 text-[35px]">.</span>
                  </label>
                  <label className="text-gray-400">
                    Des troubles de connexion?{" "}
                    <span className="text-blue-500 hover:underline hover:cursor-pointer">
                      Contactez-nous
                    </span>
                    .
                  </label>
                  <div className="login-section-components w-[100%] max-w-[455px] h-auto mt-[25px]">
                    <div className="for--login-section-components">
                      <Picture setValue={setPicture} />

                      <div className="buttons h-buttons items-center flex justify-between w-full h-auto mt-[35px]">
                        <button
                          disabled={isLoading}
                          onClick={(e) =>
                            swipe(e, "prev", "add-pp", "create-acc")
                          }
                          className="hover:opacity-[0.8] md:w-[47.5%] duration-[0.4s] flex items-center justify-center w-[100%] h-[50px] mb-[10px] sm:mb-[0] text-[17px] text-white bg-[#565B68] font-[500] rounded-[50px]"
                        >
                          Précedent
                        </button>
                        <button
                          disabled={isLoading}
                          onClick={(e) =>
                            swipe(e, "next", "add-pp", "select-team")
                          }
                          className="hover:opacity-[0.8] md:w-[47.5%] duration-[0.4s] flex items-center justify-center w-[100%] h-[50px] text-[17px] text-white bg-blue-500 font-[500] rounded-[50px]"
                        >
                          {isLoading ? (
                            <Loader />
                          ) : picture === "" ? (
                            "Passer"
                          ) : (
                            "Suivant"
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="common-for-login-step invisible select-team next-div opacity-0 w-full h-full border-white login-step items-start justify-start flex-col hidden">
                  <label className="section-name text-[37px] text-white font-bold">
                    Avez-vous une organisation
                    <span className="text-blue-500 text-[35px]">?</span>
                  </label>
                  <label className="text-gray-400">
                    Des troubles de connexion?{" "}
                    <span className="text-blue-500 hover:underline hover:cursor-pointer">
                      Contactez-nous
                    </span>
                    .
                  </label>
                  <div className="login-section-components w-[100%] max-w-[455px] h-auto mt-[25px]">
                    <div className="for--login-section-components">
                      <div className="flex items-center justify-between w-full h-auto">
                        <button
                          disabled={isLoading}
                          onClick={(e) => toggle(e, "create")}
                          className={`common-for-toogle ${
                            choice === "create"
                              ? " border-[3px] border-blue-100 "
                              : " border-[3px] border-transparent "
                          }  duration-[0.35s] mr-[15px] flex items-center justify-center w-[225px] h-[150px] rounded-[15px] bg-[#3E404A]`}
                        >
                          <div className="for--common-for-toogle flex items-center justify-center flex-col w-[90%] h-[90%] rounded-[inherit]">
                            <div className="common-for-toogle--icons flex items-center justify-center text-white">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-person-fill-add w-[45px] h-[45px]"
                                viewBox="0 0 16 16"
                              >
                                <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0Zm-2-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                <path d="M2 13c0 1 1 1 1 1h5.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.544-3.393C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4Z" />
                              </svg>
                            </div>
                            <div className="common-for-toogle--label mt-[15px] font-bold text-gray-300 texxt-[18px]">
                              <label className="hover:cursor-pointer">
                                Créer une organisation
                              </label>
                            </div>
                          </div>
                        </button>
                        <button
                          disabled={isLoading}
                          onClick={(e) => toggle(e, "join")}
                          className={`common-for-toogle ${
                            choice === "join"
                              ? " border-[3px] border-blue-100 "
                              : " border-[3px] border-transparent "
                          }  duration-[0.35s] flex items-center justify-center w-[225px] h-[150px] rounded-[15px] bg-[#3E404A]`}
                        >
                          <div className="for--common-for-toogle flex items-center justify-center flex-col w-[90%] h-[90%] rounded-[inherit]">
                            <div className="common-for-toogle--icons flex items-center justify-center text-white">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-person-fill-up w-[45px] h-[45px]"
                                viewBox="0 0 16 16"
                              >
                                <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm.354-5.854 1.5 1.5a.5.5 0 0 1-.708.708L13 11.707V14.5a.5.5 0 0 1-1 0v-2.793l-.646.647a.5.5 0 0 1-.708-.708l1.5-1.5a.5.5 0 0 1 .708 0ZM11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                <path d="M2 13c0 1 1 1 1 1h5.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.544-3.393C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4Z" />
                              </svg>
                            </div>
                            <div className="common-for-toogle--label text-center mt-[15px] font-bold text-gray-300 texxt-[18px]">
                              <label className="hover:cursor-pointer">
                                Joindre une organisation
                              </label>
                            </div>
                          </div>
                        </button>
                      </div>

                      <div className="buttons h-buttons md:flex md:items-center flex items-center justify-between w-full h-auto mt-[35px]">
                        <button
                          disabled={isLoading}
                          onClick={(e) =>
                            swipe(e, "prev", "select-team", "add-pp")
                          }
                          className="md:w-[47.5%] duration-[0.4s] flex items-center justify-center w-[100%] mb-[10px] h-[50px] text-[17px] text-white bg-[#565B68] font-[500] rounded-[50px]"
                        >
                          Précedent
                        </button>
                        <button
                          disabled={isLoading}
                          onClick={handleOrganisation}
                          className="md:w-[47.5%] duration-[0.4s] flex items-center justify-center w-[100%] mb-[10px] h-[50px] text-[17px] text-white bg-blue-500 font-[500] rounded-[50px]"
                        >
                          {isLoading ? <Loader /> : "Suivant"}
                        </button>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="common-for-login-step join-org next-div opacity-0 w-full h-full border-white login-step items-start justify-start flex-col hidden">
                  <label className="section-name text-[37px] text-white font-bold">
                    Rejoingnez une organisation
                    <span className="text-blue-500 text-[35px]">.</span>
                  </label>
                  <label className="text-gray-400">
                    Des troubles de connexion?{" "}
                    <span className="text-blue-500 hover:underline hover:cursor-pointer">
                      Contactez-nous
                    </span>
                    .
                  </label>
                  <div className="login-section-components w-[100%] max-w-[455px] h-auto mt-[25px]">
                    <div className="for--login-section-components">
                      <Input
                        field="name"
                        type="text"
                        isLoading={isLoading}
                        value={joinOrganisationId}
                        setValue={setJoinOrganisationId}
                        fieldName="Identifiant de la société"
                        placeholder="fbdc5b6f-3bce-4c77-bfae-22b48b6463e6"
                      />
                      <Input
                        field="password"
                        type="password"
                        isLoading={isLoading}
                        value={joinOrganisationSecret}
                        setValue={setJoinOrganisationSecret}
                        fieldName="Code d'accès"
                        placeholder="********"
                      />

                      <div className="buttons h-buttons md:flex md:items-center flex items-center justify-between w-full h-auto mt-[35px]">
                        <button
                          disabled={isLoading}
                          onClick={(e) =>
                            swipe(e, "prev", "join-org", "select-team")
                          }
                          className="md:w-[47.5%] duration-[0.4s] flex items-center justify-center w-[100%] mb-[10px] h-[50px] text-[17px] text-white bg-[#565B68] font-[500] rounded-[50px]"
                        >
                          Précedent
                        </button>
                        <button
                          disabled={isLoading}
                          onClick={tryJoinOrg}
                          className="md:w-[47.5%] duration-[0.4s] flex items-center justify-center w-[100%] mb-[10px] h-[50px] text-[17px] text-white bg-blue-500 font-[500] rounded-[50px]"
                        >
                          {isLoading ? <Loader /> : "Suivant"}
                        </button>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="common-for-login-step invisible create-org next-div opacity-0 w-full h-full border-white login-step items-start justify-start flex-col hidden">
                  <label className="section-name text-[37px] text-white font-bold">
                    Créez une organisation
                    <span className="text-blue-500 text-[35px]">.</span>
                  </label>
                  <label className="text-gray-400">
                    Des troubles de connexion?{" "}
                    <span className="text-blue-500 hover:underline hover:cursor-pointer">
                      Contactez-nous
                    </span>
                    .
                  </label>
                  <div className="login-section-components w-[100%] max-w-[455px] h-auto mt-[25px]">
                    <div className="for--login-section-components">
                      <Input
                        field="name"
                        type="text"
                        isLoading={isLoading}
                        value={organisationName}
                        setValue={setOrganisationName}
                        fieldName="Nom de l'organisation"
                        placeholder="Intellifrica"
                      />
                      <select
                        disabled={isLoading}
                        onChange={(e) =>
                          setOrganisationActivity(e.target.value)
                        }
                        className="flex items-center justify-between hover:cursor-pointer outline-none w-full h-[55px] px-[18px] bg-[#3E404A] rounded-[12px] font-[500] text-white mb-[15px]"
                      >
                        <option
                          disabled
                          className="text-start bg-[#3E404A] rounded-[12px] font-[500] text-white"
                        >
                          ---Secteur d'activité---
                        </option>
                        {actvities.map((obj: string) => (
                          <option
                            value={obj}
                            key={obj}
                            className="text-start bg-[#3E404A] rounded-[12px] font-[500] text-white"
                          >
                            {obj}
                          </option>
                        ))}
                        {/* <option className="text-start bg-[#3E404A] rounded-[12px] font-[500] text-white">
                          Transport de fonds
                        </option> */}
                      </select>
                      <select
                        disabled={isLoading}
                        onChange={(e) => setOrganisationLength(e.target.value)}
                        className="flex items-center justify-between hover:cursor-pointer outline-none w-full h-[55px] px-[18px] bg-[#3E404A] rounded-[12px] font-[500] text-white mb-[15px]"
                      >
                        <option
                          disabled
                          className="text-start bg-[#3E404A] rounded-[12px] font-[500] text-white"
                        >
                          ---Taille de l'entreprise---
                        </option>
                        {sizes.map((obj: string) => (
                          <option
                            value={obj}
                            key={obj}
                            className="text-start bg-[#3E404A] rounded-[12px] font-[500] text-white"
                          >
                            {obj}
                          </option>
                        ))}

                        {/* <option className="text-start bg-[#3E404A] rounded-[12px] font-[500] text-white">
                          Auto-entrepreuneur
                        </option>
                        <option className="text-start bg-[#3E404A] rounded-[12px] font-[500] text-white">
                          Petite entreprise (1-9)
                        </option>
                        <option className="text-start bg-[#3E404A] rounded-[12px] font-[500] text-white">
                          Moyenne entreprise (10-99)
                        </option>
                        <option className="text-start bg-[#3E404A] rounded-[12px] font-[500] text-white">
                          Grande entreprise (100 à +)
                        </option> */}
                      </select>

                      <div className="buttons h-buttons md:flex md:items-center flex items-center justify-between w-full h-auto mt-[35px]">
                        <button
                          disabled={isLoading}
                          onClick={(e) =>
                            swipe(e, "prev", "create-org", "select-team")
                          }
                          className="md:w-[47.5%] duration-[0.4s] flex items-center justify-center w-[100%] mb-[10px] h-[50px] text-[17px] text-white bg-[#565B68] font-[500] rounded-[50px]"
                        >
                          Précedent
                        </button>
                        <button
                          disabled={isLoading}
                          onClick={createOrganisation}
                          className="md:w-[47.5%] duration-[0.4s] flex items-center justify-center w-[100%] mb-[10px] h-[50px] text-[17px] text-white bg-blue-500 font-[500] rounded-[50px]"
                        >
                          {isLoading ? <Loader /> : "Suivant"}
                        </button>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="common-for-login-step add-org-pp next-div opacity-0 w-full h-full border-white login-step items-start justify-start flex-col hidden">
                  <label className="section-name text-[37px] text-white font-bold">
                    Ajoutez le logo de votre societe
                    <span className="text-blue-500 text-[35px]">.</span>
                  </label>
                  <label className="text-gray-400">
                    Des troubles de connexion?{" "}
                    <span className="text-blue-500 hover:underline hover:cursor-pointer">
                      Contactez-nous
                    </span>
                    .
                  </label>
                  <div className="login-section-components w-[100%] max-w-[455px] h-auto mt-[25px]">
                    <div className="for--login-section-components">
                      <Picture setValue={setOrganisationPicture} />

                      <div className="buttons h-buttons md:flex md:items-center flex items-center justify-between w-full h-auto mt-[35px]">
                        <button
                          disabled={isLoading}
                          onClick={(e) =>
                            swipe(e, "prev", "add-org-pp", "create-org")
                          }
                          className="md:w-[47.5%] duration-[0.4s] flex items-center justify-center w-[100%] mb-[10px] h-[50px] text-[17px] text-white bg-[#565B68] font-[500] rounded-[50px]"
                        >
                          Précedent
                        </button>
                        <button
                          disabled={isLoading}
                          onClick={(e) =>
                            swipe(e, "next", "add-org-pp", "conf-create-org")
                          }
                          className="md:w-[47.5%] duration-[0.4s] flex items-center justify-center w-[100%] mb-[10px] h-[50px] text-[17px] text-white bg-blue-500 font-[500] rounded-[50px]"
                        >
                          {isLoading ? (
                            <Loader />
                          ) : organisationPicture === "" ? (
                            "Passer"
                          ) : (
                            "Suivant"
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="common-for-login-step conf-create-org next-div opacity-0 w-full h-full border-white login-step items-start justify-start flex-col hidden">
                  <label className="section-name text-[37px] text-white font-bold">
                    {choice === "create" ? "Créer" : "Rejoindre"}{" "}
                    {organisationName}
                    <span className="text-blue-500 text-[35px]">?</span>
                  </label>
                  <label className="text-gray-400">
                    Des troubles de connexion?{" "}
                    <span className="text-blue-500 hover:underline hover:cursor-pointer">
                      Contactez-nous
                    </span>
                    .
                  </label>
                  <div className="login-section-components w-[100%] max-w-[455px] h-auto mt-[25px]">
                    <div className="for--login-section-components">
                      <div className="flex items-center justify-start w-full h-auto">
                        <div className="common-for-toogle duration-[0.35s] flex items-center justify-center min-w-[200px] min-h-[200px] rounded-[50%] bg-[#3E404A]">
                          <div className="for--common-for-toogle overflow-hidden object-cover flex items-center justify-center flex-col w-[95%] h-[95%] rounded-[inherit] text-white">
                            {picture === "" && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-person-bounding-box w-[100px] h-[100px]"
                                viewBox="0 0 16 16"
                              >
                                <path d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1h-3zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5zM.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5z" />
                                <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm8-9a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                              </svg>
                            )}
                            <img src={picture} alt="" />
                          </div>
                        </div>
                        <div className="common-for-toogle translate-x-[-50px] duration-[0.35s] flex items-center justify-center min-w-[200px] min-h-[200px] rounded-[50%] bg-[#3E404A] border-[5px] border-gray-500">
                          <div className="for--common-for-toogle flex items-center justify-center flex-col w-[90%] h-[90%] rounded-[inherit]">
                            <div className="common-for-toogle--icons flex items-center justify-center text-white">
                              {choice === "create" ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-person-fill-add w-[100px] h-[100px]"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0Zm-2-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                  <path d="M2 13c0 1 1 1 1 1h5.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.544-3.393C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4Z" />
                                </svg>
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-person-fill-up w-[100px] h-[100px]"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm.354-5.854 1.5 1.5a.5.5 0 0 1-.708.708L13 11.707V14.5a.5.5 0 0 1-1 0v-2.793l-.646.647a.5.5 0 0 1-.708-.708l1.5-1.5a.5.5 0 0 1 .708 0ZM11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                  <path d="M2 13c0 1 1 1 1 1h5.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.544-3.393C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4Z" />
                                </svg>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="buttons flex items-center justify-between w-full h-auto mt-[35px]">
                        <button
                          disabled={isLoading}
                          onClick={confirmCreateOrg}
                          className="hover:opacity-[0.8] duration-[0.4s] flex items-center justify-center w-full h-[50px] text-[17px] text-white bg-blue-500 font-[500] rounded-[50px]"
                        >
                          {isLoading ? <Loader /> : "Suivant"}
                        </button>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="common-for-login-step conf-phone next-div opacity-0 w-full h-full border-white login-step  items-start justify-start flex-col hidden">
                  <label className="section-name text-[37px] text-white font-bold">
                    Dernière étape
                    <span className="text-blue-500 text-[35px]">.</span>
                  </label>
                  <label className="text-gray-400">
                    Confirmez qu'il s'agit bien de votre numéro.
                  </label>
                  <div className="login-section-components w-[100%] max-w-[455px] h-auto mt-[25px]">
                    <div className="for--login-section-components">
                      <p className="text-white text-[15px] font-[400] mb-[15px]">
                        Un code vérification a été envoyé au {number}. Entrez le
                        code pour confirmer.
                      </p>
                      <Input
                        field="number"
                        type="text"
                        isLoading={isLoading}
                        value={verificationCode}
                        setValue={setVerificationCode}
                        fieldName="Code de vérification"
                        placeholder="788741"
                      />

                      <div className="buttons items-center flex justify-between w-full h-auto mt-[35px]">
                        {/* <button
                          disabled={isLoading}
                          onClick={sendCode}
                          className="hover:opacity-[0.8] md:w-[47.5%] duration-[0.4s] flex items-center justify-center w-[100%] h-[50px] mb-[10px] sm:mb-[0] text-[17px] text-white bg-red-500 font-[500] rounded-[50px]"
                        >
                          {isLoading ? <Loader /> : "Renvoyer le code"}
                        </button> */}
                        <button
                          disabled={isLoading}
                          onClick={validateProcess}
                          className="hover:opacity-[0.8] duration-[0.4s] flex items-center justify-center w-[100%] h-[50px] mb-[10px] sm:mb-[0] text-[17px] text-white bg-blue-500 font-[500] rounded-[50px]"
                        >
                          {isLoading ? <Loader /> : "Terminer"}
                        </button>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Login;
