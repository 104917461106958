import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  collection,
  query,
  where,
  onSnapshot,
  doc,
  setDoc,
  arrayUnion,
  deleteDoc,
} from "firebase/firestore";
import { Timestamp } from "firebase/firestore";
import { DocumentData } from "firebase/firestore";
import { database } from "../Firebase/databaseConfig";
import { sendEmailToPerson, sendMail } from "../Mails/handleNotifications";
import { getUserRoleInTeam } from "../Security/handleSecurity";
import { v4 as uuid } from "uuid";

type popup = {
  text: string;
  state: string;
};

const auth = getAuth();

const exist = (array: member[], value: string) => {
  return array.some(
    (obj: member) => obj.id === value && obj.role === "Manager"
  );
};

export const renderTeams = (
  setTeams: React.Dispatch<React.SetStateAction<DocumentData[]>>,
  setOrganisation: React.Dispatch<React.SetStateAction<object>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  onAuthStateChanged(auth, (user) => {
    if (user) {
      setIsLoading(true);
      let id = user.uid;

      const q = query(
        collection(database, "users"),
        where("id", "==", `${id}`)
      );

      onSnapshot(q, async (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let user = doc.data();

          const q = query(
            collection(database, "organisations"),
            where("id", "==", `${user.organisationId}`)
          );

          onSnapshot(q, async (querySnapshot) => {
            querySnapshot.forEach((obj) => {
              let organisation = obj.data();
              setOrganisation(organisation);

              let creator: string = organisation.creator;
              let members: member[] = organisation.members;

              // if (id === creator || exist(members, id)) {
              const q = query(
                collection(database, "teams"),
                where("organisationId", "==", `${organisation.id}`)
              );

              onSnapshot(q, async (querySnapshot) => {
                setTeams([]);

                querySnapshot.forEach((res) => {
                  let team = res.data();
                  let teamMembers = team.members;

                  if (id === creator || exist(members, id)) {
                    setTeams((old) => [...old, res.data()]);
                  } else if (members.some((obj: member) => obj.id === id)) {
                    if (teamMembers.some((obj: any) => obj.id === id)) {
                      setTeams((old) => [...old, res.data()]);
                    }
                  }
                });
                setIsLoading(false);
                return;
              });
              // }
              // else {
              //   alert("Vous n'avez pas accès à cette page.");

              //   setIsLoading(false);
              //   setTeams([]);
              // }
            });
          });
        });
      });
    } else {
      setIsLoading(false);
    }
  });
};

type member = {
  id: string;
  name: string;
  picture: string;
  nickname: string;
  email: string;
  role: string;
  added: Timestamp;
};

export const deleteTeam = async (
  id: string,
  setPopup: React.Dispatch<React.SetStateAction<popup>>,
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>
) => {
  deleteDoc(doc(database, "teams", `${id}`))
    .then(() => {
      setPopup({
        state: "success",
        text: "L'équipe a bien été rétirée.",
      });
      setIsVisible(true);
    })
    .catch(() => {
      setPopup({
        state: "error",
        text: "Une erreur s'est produite.",
      });
      setIsVisible(true);
    });
};

export const modifyTeam = (
  id: string | undefined,
  name: string,
  description: string,
  location: string,
  setPopup: React.Dispatch<React.SetStateAction<popup>>,
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setIsLoading(true);

  let data = {
    name: name,
    description: description,
    localisation: location,
  };

  const docRef = doc(database, "teams", `${id}`);
  setDoc(docRef, data, { merge: true })
    .then(() => {
      setPopup({
        state: "success",
        text: `Les informations ont été mises à jour.`,
      });
      setIsVisible(true);
    })
    .catch(() => {
      setPopup({
        state: "error",
        text: `Un problème est survenu.`,
      });
      setIsVisible(true);
    })
    .finally(() => {
      setIsLoading(false);
      setOpen(false);
    });
};

export const createTeam = (
  name: string,
  description: string,
  location: string,
  link: string,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setPopup: React.Dispatch<React.SetStateAction<popup>>,
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
) => {
  let teamId = uuid();
  setIsLoading(true);

  onAuthStateChanged(auth, (user) => {
    if (user) {
      let id = user.uid;

      const q = query(
        collection(database, "users"),
        where("id", "==", `${id}`)
      );

      onSnapshot(q, async (querySnapshot) => {
        querySnapshot.forEach((obj) => {
          let user = obj.data();

          const collectionRef = collection(
            database,
            // eslint-disable-next-line no-useless-concat
            "teams"
          );
          const docRef = doc(collectionRef, teamId);

          setDoc(docRef, {
            id: teamId,
            name: name,
            link: link,
            description: description,
            localisation: location,
            members: [],
            organisationId: user.organisationId,
            created: new Date(),
            projects: 0,
            creator: {
              id: user.id,
              name: user.name,
              picture: user.picture,
              nickname: user.nickname,
              email: user.email,
              role: "",
              added: new Date(),
            },
          })
            .then(() => {
              setPopup({
                state: "success",
                text: `Equipe ${name} créée.`,
              });

              setIsVisible(true);
              setOpen(false);
            })
            .catch(() => {
              setPopup({
                state: "error",
                text: "Un problème est survenu.",
              });

              setIsVisible(true);
            })
            .finally(() => {
              setIsLoading(false);
            });
        });
      });
    }
  });
};

export const addUserToTeam = (
  id: string,
  team: DocumentData,
  setPopup: React.Dispatch<React.SetStateAction<popup>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>,
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>,
  setSelected?: React.Dispatch<React.SetStateAction<string[]>>
) => {
  setIsLoading(true);
  const q = query(collection(database, "users"), where("id", "==", `${id}`));

  onSnapshot(q, async (querySnapshot) => {
    if (querySnapshot.empty) {
      setPopup({
        state: "error",
        text: "Cet utilisateur n'existe pas.",
      });
      setIsVisible(true);
      setOpen && setOpen(false);
      setSelected && setSelected([]);
      setIsLoading(false);
      return;
    }

    querySnapshot.forEach((obj) => {
      let user = obj.data();

      let data = {
        id: id,
        name: user.name,
        picture: user.picture,
        nickname: user.nickname,
        email: user.email,
        role: "Membre",
        added: new Date(),
      };

      const docRef = doc(database, "teams", team.id);
      setDoc(docRef, { members: arrayUnion(data) }, { merge: true })
        .then(() => {
          sendEmailToPerson(data.id, "added-to-team", {
            reason: "added-to-team",
            teamName: team.name,
          });
          setPopup({
            state: "success",
            text: "L'utilisateur a été bien ajouté.",
          });
          setIsVisible(true);
          setOpen && setOpen(false);
          setSelected && setSelected([]);
          setIsLoading(false);
        })
        .catch(() => {
          setPopup({
            state: "error",
            text: "Une erreur s'est produite.",
          });
          setIsVisible(true);
          setOpen && setOpen(false);
          setSelected && setSelected([]);
          setIsLoading(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
    });
  });
  setIsLoading(false);
};

export const changeUserRole = (
  id: string,
  team: DocumentData,
  role: string,
  setPopup: React.Dispatch<React.SetStateAction<popup>>,
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>
) => {
  let members = team.members;

  // eslint-disable-next-line array-callback-return
  members = members.map((obj: any) => {
    if (obj.id === id) {
      return { ...obj, role: role };
    }
    return obj;
  });

  console.log(members);

  const docRef = doc(database, "teams", `${team.id}`);
  setDoc(docRef, { members: members }, { merge: true })
    .then(() => {
      if (role === "Manager") {
        sendEmailToPerson(id, "promoted-manager-team", {
          reason: "promoted-manager-team",
          teamName: team.name,
        });
      }

      setPopup({
        state: "success",
        text: "Role modifié !",
      });
      setIsVisible(true);
    })
    .catch(() => {
      setPopup({
        state: "error",
        text: "Une erreur s'est produite.",
      });
      setIsVisible(true);
    });
};

export const deleteMember = (
  id: string,
  team: DocumentData,
  setPopup: React.Dispatch<React.SetStateAction<popup>>,
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>
) => {
  let members = team.members;

  members = members.filter((obj: any) => obj.id !== id);

  const docRef = doc(database, "teams", `${team.id}`);
  setDoc(docRef, { members: members }, { merge: true })
    .then(() => {
      setPopup({
        state: "success",
        text: "Membre rétiré.",
      });
      setIsVisible(true);
    })
    .catch(() => {
      setPopup({
        state: "error",
        text: "Une erreur s'est produite.",
      });
      setIsVisible(true);
    });
};
