import React, { useState, useEffect, useRef } from "react";
import { getUserRole } from "../Backend/Security/handleSecurity";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import CreateTeam from "../TeamManagement/Components/CreateTeam";
import LoggedOut from "./LoggedOut";
import SearchBar from "./SearchBar";
import "../index.css";

interface Props {
  currentSection: string;
  setOpenNotifications: React.Dispatch<React.SetStateAction<boolean>>;
  setVal: React.Dispatch<React.SetStateAction<string>>;
}

function NavBar({ setVal, setOpenNotifications, currentSection }: Props) {
  const auth = getAuth();
  const navigate = useNavigate();
  const divRef = useRef<HTMLDivElement>(null);
  const dashboardRef = useRef<HTMLDivElement>(null);

  const [role, setRole] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [authed, setAuthed] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [current, setCurrent] = useState<string>("dashboard");

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [open]);

  useEffect(() => {
    getUserRole(setRole, setIsLoading);
  }, []);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        setAuthed(false);
      }
    });
  }, [auth]);

  const logOut = () => {
    const result = window.confirm(
      "Voulez-vous vraiment vous deconnecter de votre session?"
    );

    if (result) {
      signOut(auth)
        .then(() => {
          navigate("/auth");
        })
        .catch((error) => {
          alert("Une erreur s'est produite.");
        });
    }
  };

  const handleSectionClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    name: string
  ) => {
    const target = event.target as HTMLDivElement;
    const width = target.offsetWidth;
    const left = target.offsetLeft;

    let el = document.getElementById("progress-line-fill");
    let line = document.getElementById("line") as HTMLDivElement;

    let lineEnd = line.offsetLeft + line.offsetWidth;

    if (el) {
      if (left + width < lineEnd) {
        el.style.left = `${left}px`;
        el.style.width = `${width}px`;
      } else {
        el.style.left = `${lineEnd - width}px`;
        el.style.width = `${width}px`;
      }
    }

    setCurrent(name);
    setVal(name);

    window.history.replaceState(null, "", `/${name}`);
  };

  useEffect(() => {
    if (role !== "") {
      if (role === "creator" || role === "manager") {
        if (dashboardRef.current) {
          dashboardRef.current.click();
        }
      }
    }
  }, [role]);

  useEffect(() => {
    if (divRef.current) {
      divRef.current.click();
    }
  }, []);

  return (
    <div className="nav-bar sticky top-0 z-[600] custom-shadow flex items-center justify-center w-full h-auto pt-[25px] pb-[14px] bg-white">
      <CreateTeam open={open} setOpen={setOpen} />
      {!authed && <LoggedOut />}
      <div className="for--nav-bar z-[1] flex items-start justify-start flex-col w-[92.5%] h-full">
        {/* TOP PART */}
        <div className="nav-bar--top-part flex-wrap md:flex items-center justify-between w-full h-auto">
          <div className="section-name flex items-center justify-start w-auto h-auto">
            <div className="section-name--logo flex items-center justify-center fill-white text-white mr-[9px] w-[30px] h-[30px] rounded-[50%] bg-[#333] p-[5px]">
              {current === "dashboard" && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-[25px] h-[25px]"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5m.75-9l3-3 2.148 2.148A12.061 12.061 0 0116.5 7.605"
                  />
                </svg>
              )}
              {current === "business" && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-[25px] h-[25px]"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z"
                  />
                </svg>
              )}
              {current === "teams" && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="20"
                  viewBox="0 -960 960 960"
                  width="20"
                  className="w-[25px] h-[25px]"
                >
                  <path d="M48-264v-57.057Q48-360 87-384t105-24q14 0 26 1t23 2.739Q229-386 222.5-365q-6.5 21-6.5 43.222V-264H48Zm216 0v-57.629Q264-350 278.5-372q14.5-22 43.5-39t69-25q40-8 89.029-8 49.971 0 89.471 8 39.5 8 68.5 24.5t43.5 39Q696-350 696-321.629V-264H264Zm480 0v-58q0-22-6.5-42.5T719-404q9-2 20.5-3t28.5-1q66 0 105 23.85t39 63.45v56.7H744ZM191.788-456Q162-456 141-477.026q-21-21.027-21-50.553Q120-558 141.026-579q21.027-21 50.553-21Q222-600 243-579.239t21 51.451Q264-498 243.239-477q-20.761 21-51.451 21Zm576 0Q738-456 717-477.026q-21-21.027-21-50.553Q696-558 717.026-579q21.027-21 50.553-21Q798-600 819-579.239t21 51.451Q840-498 819.239-477q-20.761 21-51.451 21Zm-288.234-36Q435-492 403.5-523.5T372-600q0-45 31.5-76.5T480-708q45 0 76.5 31.5t31.5 76.946Q588-555 556.5-523.5T479.554-492Z" />
                </svg>
              )}
              {current === "projects" && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-[25px] h-[25px]"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 6.878V6a2.25 2.25 0 012.25-2.25h7.5A2.25 2.25 0 0118 6v.878m-12 0c.235-.083.487-.128.75-.128h10.5c.263 0 .515.045.75.128m-12 0A2.25 2.25 0 004.5 9v.878m13.5-3A2.25 2.25 0 0119.5 9v.878m0 0a2.246 2.246 0 00-.75-.128H5.25c-.263 0-.515.045-.75.128m15 0A2.25 2.25 0 0121 12v6a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 18v-6c0-.98.626-1.813 1.5-2.122"
                  />
                </svg>
              )}
              {current === "settings" && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-[25px] h-[25px]"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
              )}
            </div>
            <label className="text-black font-[500] text-[22px]">
              {current === "dashboard" && "Tableau de bord"}
              {current === "business" && "Entreprise"}
              {current === "teams" && "Equipes"}
              {current === "projects" && "Projets"}
              {current === "settings" && "Parametres"}
            </label>
          </div>
          {/* <div className="search-part mt-[12px] md:mt-0">
            <SearchBar />
          </div> */}
        </div>
        {/* BOTTOM PART */}
        <div className="nav-bar--bottom-part relative mt-[17px] pb-[7px] flex items-center justify-center w-full h-auto">
          <div className="all-sections--action flex flex-wrap-reverse md:flex items-center justify-end md:justify-between max-w-full w-full h-auto">
            <div className="w-full md:w-auto h-auto flex items-center justify-start max-w-full overflow-y-auto">
              <div className="all-sections flex items-center justify-start w-full md:w-auto h-auto">
                {!isLoading && role !== "member" && (
                  <div
                    ref={dashboardRef}
                    onClick={(e) => handleSectionClick(e, "dashboard")}
                    className="common-for-section hover:cursor-pointer flex items-center justify-center w-auto h-auto mr-[40px]"
                  >
                    <label
                      className={`section-name hover:cursor-pointer text-[#00000090] text-[14px] font-[500] ${
                        current === "dashboard" && "text-black"
                      }`}
                    >
                      Tableau de bord
                    </label>
                  </div>
                )}
                <div
                  ref={divRef}
                  onClick={(e) => handleSectionClick(e, "teams")}
                  className="common-for-section hover:cursor-pointer flex items-center justify-center w-auto h-auto mr-[40px]"
                >
                  <label
                    className={`section-name hover:cursor-pointer text-[#00000090] text-[14px] font-[500] ${
                      current === "teams" && "text-black"
                    }`}
                  >
                    Equipes
                  </label>
                </div>
                <div
                  onClick={(e) => handleSectionClick(e, "projects")}
                  className="common-for-section active hover:cursor-pointer flex items-center justify-center w-auto h-auto mr-[40px]"
                >
                  <label
                    className={`section-name hover:cursor-pointer text-[#00000090] text-[14px] font-[500] ${
                      current === "projects" && "text-black"
                    }`}
                  >
                    Projets
                  </label>
                </div>
                {!isLoading && role !== "member" && (
                  <div
                    onClick={(e) => handleSectionClick(e, "business")}
                    className="common-for-section hover:cursor-pointer flex items-center justify-center w-auto h-auto mr-[40px]"
                  >
                    <label
                      className={`section-name hover:cursor-pointer text-[#00000090] text-[14px] font-[500] ${
                        current === "business" && "text-black"
                      }`}
                    >
                      Entreprise
                    </label>
                  </div>
                )}
                <div
                  onClick={(e) => handleSectionClick(e, "settings")}
                  className="common-for-section hover:cursor-pointer flex items-center justify-center w-auto h-auto mr-[40px]"
                >
                  <label
                    className={`section-name hover:cursor-pointer text-[#00000090] text-[14px] font-[500] ${
                      current === "settings" && "text-black"
                    }`}
                  >
                    Paramètres
                  </label>
                </div>
              </div>
            </div>
            <div className="action w-auto h-auto flex items-center justify-center mb-[0.6em] md:mb-0">
              <button
                onClick={logOut}
                className="flex items-center justify-center hover:text-red-500 w-auto px-[10px] h-[35px] bg-[#F4F5F7] rounded-[3px] mr-[0.5em]"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-[18px] h-[18px]"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9"
                  />
                </svg>
              </button>
              {role !== "member" && (
                <button
                  disabled={currentSection === "projects"}
                  onClick={(e) => setOpenNotifications(true)}
                  className="flex items-center justify-center hover:text-blue-500 w-auto px-[10px] h-[35px] bg-[#F4F5F7] rounded-[3px] mr-[0.5em]"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-[18px] h-[18px]"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"
                    />
                  </svg>
                </button>
              )}
              {role !== "member" && (
                <button
                  onClick={(e) => setOpen(true)}
                  className="flex items-center justify-center w-auto h-[35px] px-[8px] bg-[#F4F5F7] rounded-[3px]"
                >
                  <label className="text-[#353740] font-[400] text-[14px] mr-[8px] hover:cursor-pointer">
                    Créer une équipe
                  </label>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-plus text-[#353740] w-[20px] h-[20px]"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                  </svg>
                </button>
              )}
            </div>
          </div>
          <div
            id="line"
            className="progress-line absolute bottom-0 w-full h-[2px] rounded-[10px] bg-[#F4F5F7]"
          >
            <div
              id="progress-line-fill"
              className="absolute h-[2px] rounded-[10px] bg-[#333]"
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NavBar;
