import React, { useEffect } from "react";
import { Toaster, toast } from "sonner";
import "../index.css";

type popup = {
  text: string;
  state: string;
};

interface Props {
  infos: popup;
  isVisible: boolean;
}

function Popup({ isVisible, infos }: Props) {
  useEffect(() => {
    if (isVisible) {
      if (infos.state === "success") {
        toast.success(infos.text);
      } else {
        toast.error(infos.text);
      }
    }
  }, [isVisible, infos]);

  if (!isVisible) {
    return null;
  }

  return (
    <Toaster
      className="z-[999]"
      position="top-center"
      expand={false}
      richColors
    />
  );
}

export default Popup;
