import React from "react";
import "../index.css";

function Loader() {
  return (
    <div className="loader-spinner z-[1] w-[22px] h-[22px] flex items-center justify-center">
      <img
        src="https://cdn-icons-png.flaticon.com/512/10349/10349329.png"
        alt="spinner"
        className="w-full h-full"
      />
    </div>
  );
}

export default Loader;
