import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";
import "../../index.css";
Chart.register(ArcElement);

interface Props {
  stats?: number[];
}

const DoughnutChart = ({ stats }: Props) => {
  const [labels, setLabels] = useState<string[]>([]);
  const [values, setValues] = useState<number[]>([]);
  const [colors, setColors] = useState<string[]>([]);

  useEffect(() => {
    if (stats !== undefined && stats[0] === 0) {
      setValues([1, 0, 0, 0]);
      setColors(["#e7e7e7", "#f97316", "#478BF2", "#51B069"]);
      setLabels([
        "Toutes les tâches",
        "Tâches à faire",
        "Tâches en cours",
        "Tâches terminées",
      ]);
    } else if (stats !== undefined && stats[0] !== 0) {
      setValues([stats[1], stats[2], stats[3]]);
      setColors(["#f97316", "#478BF2", "#51B069"]);
      setLabels(["Tâches à faire", "Tâches en cours", "Tâches terminées"]);
    }
  }, [stats]);

  const data = {
    labels: labels,
    datasets: [
      {
        label: "Tasks",
        data: values,
        backgroundColor: colors,
        borderWidth: 0.1,
        hoverOffset: 4,
      },
    ],
    options: {
      tooltips: {
        enabled: true,
      },
    },
  };

  return (
    <div className="w-full h-auto">
      <Doughnut width="100%" data={data} />
    </div>
  );
};

export default DoughnutChart;
