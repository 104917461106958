import { Fragment, useState, useEffect } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { allCountries } from "../../countries";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import "../../index.css";

type country = {
  id: string;
  name: string;
  code: string;
  flag: string;
};

interface Props {
  value: country | undefined;
  setValue: React.Dispatch<React.SetStateAction<country | undefined>>;
}

export default function Select({ value, setValue }: Props) {
  const [countries, setCountries] = useState<country[]>([]);
  const [selectedValue, setSelectedValue] = useState<string | undefined>();

  useEffect(() => {
    for (const country of countries) {
      if (country.id === selectedValue) {
        setValue(country);
      }
    }
  }, [countries, selectedValue, setValue]);

  useEffect(() => {
    setCountries([]);
    allCountries.forEach((obj: any) => {
      let val = {
        id: obj.alpha2Code,
        name: obj.name,
        code: `+${obj.callingCodes[0]}`,
        flag: obj.flags.png,
      };

      setCountries((old) => [...old, val]);
    });
  }, []);
  return (
    <div className="w-full mb-[0.65em] z-[9999]">
      <Listbox
        value={selectedValue}
        onChange={(newValue) => setSelectedValue(newValue)}
      >
        <div className="relative">
          <Listbox.Button className="relative hover:cursor-pointer w-full h-[50px] cursor-default rounded-lg bg-[#3E404A] pl-3 pr-10 text-left custom-border focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
            <small className="text-white text-[10px] mb-[2px]">
              Choississez un pays
            </small>
            {value && (
              <span className="block truncate text-white">
                {value.name} ({value.code})
              </span>
            )}

            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute z-[999] hover:cursor-pointer mt-1 max-h-60 w-full overflow-auto rounded-md bg-[#3E404A] py-1 text-base custom-shadow ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              <Listbox.Option
                disabled
                value="None"
                className={({ active }) =>
                  `relative hover:cursor-pointer select-none py-2 pl-10 pr-4 hover:bg-gray-600`
                }
              >
                {({ selected }) => (
                  <>
                    <span
                      className={`flex items-center justify-start disabled:hover:cursor-default hover:cursor-pointer truncate ${
                        selected ? "font-medium" : "font-normal"
                      }`}
                    >
                      <div className="dropdown-label flex items-center justify-center w-auto text-white font-bold hover:cursor-pointer">
                        <label className="hover:cursor-pointer text-white">
                          Favoris
                        </label>
                      </div>
                    </span>
                  </>
                )}
              </Listbox.Option>
              <Listbox.Option
                className={({ active }) =>
                  `relative hover:cursor-pointer select-none py-2 pl-10 pr-4 hover:bg-gray-600`
                }
                value="BF"
              >
                {({ selected }) => (
                  <>
                    <span
                      className={`flex items-center justify-start hover:cursor-pointer truncate ${
                        selected ? "font-medium" : "font-normal"
                      }`}
                    >
                      <div className="dropdown-icon flex items-center justify-center w-[25px] h-[25px] mr-[10px] text-blue-500">
                        <img
                          src="https://flagcdn.com/w320/bf.png"
                          alt={"BF" + "-flag"}
                        />
                      </div>
                      <div className="dropdown-label flex items-center justify-center w-auto text-white font-bold hover:cursor-pointer">
                        <label className="hover:cursor-pointer text-white">
                          Burkina Faso(+226)
                        </label>
                      </div>
                    </span>
                    {selected ? (
                      <span className="absolute hover:cursor-pointer inset-y-0 left-0 flex items-center pl-3 text-white">
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>

              <Listbox.Option
                className={({ active }) =>
                  `relative hover:cursor-pointer select-none py-2 pl-10 pr-4 hover:bg-gray-600`
                }
                value="MA"
              >
                {({ selected }) => (
                  <>
                    <span
                      className={`flex items-center justify-start hover:cursor-pointer truncate ${
                        selected ? "font-medium" : "font-normal"
                      }`}
                    >
                      <div className="dropdown-icon flex items-center justify-center w-[25px] h-[25px] mr-[10px] text-blue-500">
                        <img
                          src="https://flagcdn.com/w320/ma.png"
                          alt={"MA" + "-flag"}
                        />
                      </div>
                      <div className="dropdown-label flex items-center justify-center w-auto text-white font-bold hover:cursor-pointer">
                        <label className="hover:cursor-pointer text-white">
                          Maroc(+212)
                        </label>
                      </div>
                    </span>
                    {selected ? (
                      <span className="absolute hover:cursor-pointer inset-y-0 left-0 flex items-center pl-3 text-white">
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>

              <Listbox.Option
                className={({ active }) =>
                  `relative hover:cursor-pointer select-none py-2 pl-10 pr-4 hover:bg-gray-600`
                }
                value="FR"
              >
                {({ selected }) => (
                  <>
                    <span
                      className={`flex items-center justify-start hover:cursor-pointer truncate ${
                        selected ? "font-medium" : "font-normal"
                      }`}
                    >
                      <div className="dropdown-icon flex items-center justify-center w-[25px] h-[25px] mr-[10px] text-blue-500">
                        <img
                          src="https://flagcdn.com/w320/fr.png"
                          // eslint-disable-next-line no-useless-concat
                          alt={"FR" + "-flag"}
                        />
                      </div>
                      <div className="dropdown-label flex items-center justify-center w-auto text-white font-bold hover:cursor-pointer">
                        <label className="hover:cursor-pointer text-white">
                          France(+33)
                        </label>
                      </div>
                    </span>
                    {selected ? (
                      <span className="absolute hover:cursor-pointer inset-y-0 left-0 flex items-center pl-3 text-white">
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
              <Listbox.Option
                disabled
                value="None"
                className={({ active }) =>
                  `relative hover:cursor-pointer select-none py-2 pl-10 pr-4 hover:bg-gray-600`
                }
              >
                {({ selected }) => (
                  <>
                    <span
                      className={`flex items-center justify-start disabled:hover:cursor-none hover:cursor-pointer truncate ${
                        selected ? "font-medium" : "font-normal"
                      }`}
                    >
                      <div className="dropdown-label flex items-center justify-center w-auto text-white font-bold hover:cursor-pointer">
                        <label className="hover:cursor-pointer text-white">
                          Tous les pays
                        </label>
                      </div>
                    </span>
                  </>
                )}
              </Listbox.Option>
              {countries.map((obj: country) => (
                <Listbox.Option
                  key={obj.code}
                  className={({ active }) =>
                    `relative hover:cursor-pointer select-none py-2 pl-10 pr-4 hover:bg-gray-600`
                  }
                  value={obj.id}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`flex items-center justify-start hover:cursor-pointer truncate ${
                          selected ? "font-medium" : "font-normal"
                        }`}
                      >
                        <div className="dropdown-icon flex items-center justify-center w-[25px] h-[25px] mr-[10px] text-blue-500">
                          <img src={obj.flag} alt={obj.name + "-flag"} />
                        </div>
                        <div className="dropdown-label flex items-center justify-center w-auto text-white font-bold hover:cursor-pointer">
                          <label className="hover:cursor-pointer text-white">
                            {obj.name}({obj.code})
                          </label>
                        </div>
                      </span>
                      {selected ? (
                        <span className="absolute hover:cursor-pointer inset-y-0 left-0 flex items-center pl-3 text-white">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}
