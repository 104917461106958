import React, { useEffect, useState } from "react";
import "../../index.css";
import { deleteMessage, cancelProof } from "../../Backend/Chat/handleChat";
import { Timestamp, DocumentData } from "firebase/firestore";
import ImageViewer from "../ImageViewer";

type deletedFormat = {
  isDeleted: boolean;
  message: string;
};

interface Props {
  id: string;
  date: string;
  type: string;
  media: string;
  author: string;
  admin: boolean;
  userId: string;
  message: string;
  section?: string;
  task: DocumentData;
  timeStamp: Timestamp;
  project: DocumentData;
  deleted: deletedFormat;
}

function Both({
  id,
  date,
  type,
  author,
  media,
  message,
  timeStamp,
  project,
  task,
  deleted,
  admin,
  section,
  userId,
}: Props) {
  const [open, setOpen] = useState<boolean>(false);

  const deleteM = () => {
    deleteMessage(userId, project, task, id, section);
  };

  const cancelP = () => {
    cancelProof(userId, project, task, id, section, true);
  };

  return (
    <div
      className={`common-for-msg mb-[1em] w-full h-auto flex ${
        author === "Vous" ? "items-end" : "items-start"
      }  justify-start flex-col`}
    >
      <ImageViewer open={open} setOpen={setOpen} image={media} />
      <label className="text-[11px] flex items-center text-gray-400 font-[400] w-auto">
        {author}
        <span className="mx-[3px]">·</span>
        <span>{date}</span>
        {author === "Vous" && (
          <React.Fragment>
            <span className="mx-[3px]">·</span>
            <button
              onClick={deleteM}
              disabled={
                deleted.isDeleted ||
                (section === "Justificatifs" && !admin && author !== "Vous")
              }
              className="text-red-500 flex items-center justify-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-[12px] h-[12px]"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                />
              </svg>
            </button>
          </React.Fragment>
        )}
        {section === "Justificatifs" && admin && author !== "Vous" && (
          <div className="flex items-center">
            <span className="mx-[3px]">·</span>
            <button
              onClick={cancelP}
              disabled={deleted.isDeleted}
              className="text-orange-500 flex items-center justify-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-[12px] h-[12px]"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m6.75 12H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                />
              </svg>
            </button>
          </div>
        )}
      </label>

      {author === "Vous" ? (
        <div className="w-auto max-w-[85%] h-auto px-[8px] py-[6px] mt-[3px] text-start whitespace-normal border-[1.75px] border-[#928af9] bg-[#928af9] flex items-start justify-start flex-col rounded-[15px] rounded-tr-none text-white">
          {deleted.isDeleted ? (
            <label className="text-[14px] italic">{deleted.message}</label>
          ) : (
            <div
              onClick={(e) => setOpen(true)}
              className="flex items-start justify-start flex-col"
            >
              <img
                className="max-w-[200px] max-h-[200px] w-full h-full object-cover rounded-[8px]"
                src={media}
                alt=""
              />

              <label className="text-[14px] max-w-[200px] max-h-[200px] mt-[7px] text-white">
                {message}
              </label>
            </div>
          )}
        </div>
      ) : (
        <div className="w-auto text-[#333] max-w-[85%] h-auto px-[8px] py-[6px] mt-[3px] text-start whitespace-normal border-[1.75px] border-[#f2f6f9] bg-[#f2f6f9] flex items-start justify-start rounded-[15px] rounded-tl-none">
          {deleted.isDeleted ? (
            <label className="text-[14px] italic">{deleted.message}</label>
          ) : (
            <div
              onClick={(e) => setOpen(true)}
              className="flex items-start justify-start flex-col"
            >
              <img
                className="max-w-[200px] max-h-[200px] w-full h-full object-cover rounded-[8px]"
                src={media}
                alt=""
              />

              <label className="text-[14px] max-w-[200px] max-h-[200px] mt-[7px] text-[#333]">
                {message}
              </label>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Both;
