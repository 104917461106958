import React from "react";
import moment from "moment";
import { Timestamp } from "firebase/firestore";

interface Props {
  timestamp: Timestamp;
  displayTime?: boolean;
}

function TimeStamp({ timestamp, displayTime }: Props) {
  const formattedTime = moment.unix(timestamp.seconds).format("HH:mm");
  const formattedDate = moment.unix(timestamp.seconds).format("DD/MM/YYYY");

  return (
    <span>
      {formattedDate}
      {displayTime && ` ⸱ ${formattedTime}`}
    </span>
  );
}

export default TimeStamp;
