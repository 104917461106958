import { useEffect, useState } from "react";
import { Tab } from "@headlessui/react";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

interface Props {
  open: boolean;
  selected: string;
  setSelected: React.Dispatch<React.SetStateAction<string>>;
}

export default function SwitchForChat({ open, selected, setSelected }: Props) {
  const [categories] = useState({
    Chat: [],
    Justificatifs: [],
  });

  useEffect(() => {
    if (!open && selected !== "Chat") {
      setSelected("Chat");
    }
  }, [open, selected, setSelected]);

  useEffect(() => {
    if (selected === "") {
      setSelected(Object.keys(categories)[0]);
    }
  }, [categories, selected, setSelected]);

  return (
    <div className="w-full max-w-md px-2 sm:px-0">
      <Tab.Group>
        <Tab.List className="flex space-x-1 rounded-xl backdrop-blur-sm bg-gray-100 p-1">
          {Object.keys(categories).map((category) => (
            <Tab
              onClick={(e) => setSelected(category)}
              key={category}
              className={() =>
                classNames(
                  "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-[#333]",
                  "ring-transparent ring-opacity-60 ring-offset-0 duration-200 ring-offset-blue-400 focus:outline-none focus:ring-2",
                  category === selected
                    ? "bg-white shadow text-blue-600"
                    : "text-[#333] hover:bg-[#ffffffbe] hover:text-[#333]"
                )
              }
            >
              {category}
            </Tab>
          ))}
        </Tab.List>
      </Tab.Group>
    </div>
  );
}
