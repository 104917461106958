import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  collection,
  query,
  where,
  onSnapshot,
  doc,
  setDoc,
  getDoc,
  updateDoc,
  arrayUnion,
  DocumentData,
  deleteDoc,
  Timestamp,
} from "firebase/firestore";
import { v4 as uuid } from "uuid";
import { database } from "../Firebase/databaseConfig";
import { sendEmailToPerson } from "../Mails/handleNotifications";

type popup = {
  text: string;
  state: string;
};

const auth = getAuth();

const exist = (array: any[], value: string) => {
  return array.some((obj: any) => obj.id === value && obj.role === "Manager");
};

export const isUserProofed = (
  members: any[],
  setIsProofed: React.Dispatch<React.SetStateAction<boolean>>
) => {
  onAuthStateChanged(auth, (user) => {
    if (user) {
      let id = user.uid;

      setIsProofed(members.some((obj: any) => obj.id === id && obj.completed));
    }
  });
};

export const finishProject = (
  id: string,
  status: string,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const docRef = doc(database, "projects", `${id}`);

  updateDoc(docRef, {
    status: status,
    finished: status === "finished" ? new Date() : null,
  }).finally(() => {
    setIsLoading(false);
  });
};

export const createProject = (
  name: string,
  description: string,
  location: object,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setPopup: React.Dispatch<React.SetStateAction<popup>>,
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  end: any,
  status?: string,
  start?: any
) => {
  setIsLoading(true);
  onAuthStateChanged(auth, (user) => {
    if (user) {
      let id = user.uid;

      const q = query(
        collection(database, "users"),
        where("id", "==", `${id}`)
      );

      onSnapshot(q, async (querySnapshot) => {
        querySnapshot.forEach((res) => {
          let user = res.data();

          const q = query(
            collection(database, "organisations"),
            where("id", "==", `${user.organisationId}`)
          );

          onSnapshot(q, async (querySnapshot) => {
            querySnapshot.forEach((obj) => {
              let organisation = obj.data();

              let creator: string = organisation.creator;
              let members: member[] = organisation.members;

              if (id === creator || exist(members, id)) {
                let projectId = uuid();

                let data = {
                  id: projectId,
                  name: name,
                  description: description,
                  status: status,
                  organisationId: organisation.id,
                  location: location,
                  created: new Date(),
                  members: [],
                  teams: [],
                  finished: new Date(),
                  creator: {
                    name: user.name,
                    email: user.email,
                    id: user.id,
                    nickname: user.nickname,
                    picture: user.picture,
                  },
                  end: end,
                  start: start === undefined ? new Date() : start,
                };

                const docRef = doc(database, "projects", `${projectId}`);

                setDoc(docRef, data)
                  .then(() => {
                    setPopup({
                      state: "success",
                      text: `Le projet a été créé.`,
                    });
                    setIsVisible(true);
                  })
                  .catch(() => {
                    setPopup({
                      state: "error",
                      text: `Une erreur est survenue.`,
                    });
                    setIsVisible(true);
                  })
                  .finally(() => {
                    setIsLoading(false);
                    setOpen(false);
                  });
              }
              // else {
              //   alert("Vous n'avez pas accès à cette page.");
              //   setIsLoading(false);
              // }
            });
          });
        });
      });
    } else {
      setIsLoading(false);
    }
  });
};

export const saveProject = (
  project: DocumentData,
  name: string,
  description: string,
  location: object,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setPopup: React.Dispatch<React.SetStateAction<popup>>,
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  end?: any,
  start?: any
) => {
  setIsLoading(true);

  let data = {
    name: name,
    description: description,
    location: location,
    end: end === undefined ? project.end : end,
    start: start === undefined ? project.start : start,
  };

  const docRef = doc(database, "projects", `${project.id}`);

  updateDoc(docRef, data)
    .then(() => {
      setPopup({
        state: "success",
        text: `Vos modifications ont été prises en compte.`,
      });
      setIsVisible(true);
    })
    .catch(() => {
      setPopup({
        state: "error",
        text: `Une erreur est survenue.`,
      });
      setIsVisible(true);
    })
    .finally(() => {
      setIsLoading(false);
      setOpen(false);
    });
};

export const deleteProject = async (
  id: string,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setPopup: React.Dispatch<React.SetStateAction<popup>>,
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setIsLoading(true);

  await deleteDoc(doc(database, "projects", `${id}`))
    .then(() => {
      setPopup({
        state: "success",
        text: `Le projet a été supprimée.`,
      });
      setIsVisible(true);
    })
    .catch(() => {
      setPopup({
        state: "error",
        text: `Un problème est survenu.`,
      });
      setIsVisible(true);
    })
    .finally(() => {
      setIsLoading(false);
    });
};

export const renderProjects = (
  setProjects: React.Dispatch<React.SetStateAction<DocumentData[]>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setIsLoading(true);
  onAuthStateChanged(auth, (user) => {
    if (user) {
      let id = user.uid;

      const q = query(
        collection(database, "users"),
        where("id", "==", `${id}`)
      );

      onSnapshot(q, async (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let user = doc.data();

          const q = query(
            collection(database, "organisations"),
            where("id", "==", `${user.organisationId}`)
          );

          onSnapshot(q, async (querySnapshot) => {
            querySnapshot.forEach((obj) => {
              let organisation = obj.data();

              let creator: string = organisation.creator;
              let members: member[] = organisation.members;

              const q = query(
                collection(database, "projects"),
                where("organisationId", "==", `${organisation.id}`)
              );

              onSnapshot(q, async (querySnapshot) => {
                setProjects([]);
                querySnapshot.forEach((obj) => {
                  let project = obj.data();
                  let projectMembers = project.members;

                  if (id === creator || exist(members, id)) {
                    setProjects((old) => [...old, obj.data()]);
                  } else if (members.some((obj: member) => obj.id === id)) {
                    if (projectMembers.some((obj: any) => obj.id === id)) {
                      setProjects((old) => [...old, obj.data()]);
                    }
                  }
                });
                setIsLoading(false);
              });
              // else {
              //   alert("Vous n'avez pas accès à cette page.");
              //   setIsLoading(false);
              // }
            });
          });
        });
      });
    } else {
      setIsLoading(false);
    }
  });
};

export const getAllOrgMembers = (
  setOrgMembers: React.Dispatch<React.SetStateAction<DocumentData[]>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setIsLoading(true);
  onAuthStateChanged(auth, (user) => {
    if (user) {
      let id = user.uid;

      const q = query(
        collection(database, "users"),
        where("id", "==", `${id}`)
      );

      onSnapshot(q, async (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let user = doc.data();

          const q = query(
            collection(database, "organisations"),
            where("id", "==", `${user.organisationId}`)
          );

          onSnapshot(q, async (querySnapshot) => {
            setOrgMembers([]);
            querySnapshot.forEach((obj) => {
              let organisation = obj.data();

              let creator: string = organisation.creator;
              let members: member[] = organisation.members;

              if (id === creator || exist(members, id)) {
                setOrgMembers(organisation.members);
                setIsLoading(false);
              }
              // else {
              //   alert("Vous n'avez pas accès à cette page.");
              //   setIsLoading(false);
              // }
            });
          });
        });
      });
    } else {
      setIsLoading(false);
    }
  });
};

export const getAllTeams = (
  setTeams: React.Dispatch<React.SetStateAction<DocumentData[]>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setIsLoading(true);
  onAuthStateChanged(auth, (user) => {
    if (user) {
      let id = user.uid;

      const q = query(
        collection(database, "users"),
        where("id", "==", `${id}`)
      );

      onSnapshot(q, async (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let user = doc.data();

          const q = query(
            collection(database, "organisations"),
            where("id", "==", `${user.organisationId}`)
          );

          onSnapshot(q, async (querySnapshot) => {
            querySnapshot.forEach((obj) => {
              let organisation = obj.data();

              let creator: string = organisation.creator;
              let members: member[] = organisation.members;

              if (id === creator || exist(members, id)) {
                const q = query(
                  collection(database, "teams"),
                  where("organisationId", "==", `${organisation.id}`)
                );

                onSnapshot(q, async (querySnapshot) => {
                  setTeams([]);
                  querySnapshot.forEach((obj) => {
                    setTeams((old) => [...old, obj.data()]);
                  });
                  setIsLoading(false);
                });
              }
            });
          });
        });
      });
    } else {
      setIsLoading(false);
    }
  });
};

export const getProject = (
  projectId: string,
  setProject: React.Dispatch<React.SetStateAction<DocumentData>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setIsLoading(true);
  onAuthStateChanged(auth, (user) => {
    if (user) {
      let id = user.uid;

      const q = query(
        collection(database, "users"),
        where("id", "==", `${id}`)
      );

      onSnapshot(q, async (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let user = doc.data();

          const q = query(
            collection(database, "organisations"),
            where("id", "==", `${user.organisationId}`)
          );

          onSnapshot(q, async (querySnapshot) => {
            querySnapshot.forEach((obj) => {
              let organisation = obj.data();

              let creator: string = organisation.creator;
              let members: member[] = organisation.members;

              const q = query(
                collection(database, "projects"),
                where("id", "==", `${projectId}`)
              );

              onSnapshot(q, async (querySnapshot) => {
                querySnapshot.forEach((obj) => {
                  let projectMembers = obj.data().members;

                  if (
                    id === creator ||
                    exist(members, id) ||
                    projectMembers.some((obj: any) => obj.id === id)
                  ) {
                    setProject(obj.data());
                  }
                });
                setIsLoading(false);
              });

              // else {
              //   alert("Vous n'avez pas accès à cette page.");
              //   setIsLoading(false);
              // }
            });
          });
        });
      });
    } else {
      setIsLoading(false);
    }
  });
};

const timestampToDate = (timestamp: Timestamp) => {
  const seconds = timestamp.seconds;
  const milliseconds = timestamp.nanoseconds / 1000000;
  return new Date(seconds * 1000 + milliseconds);
};

export const getProjectTasks = (
  projectId: string,
  setToDoTasks: React.Dispatch<React.SetStateAction<DocumentData[]>>,
  setDoingTasks: React.Dispatch<React.SetStateAction<DocumentData[]>>,
  setFinishedTasks: React.Dispatch<React.SetStateAction<DocumentData[]>>,
  setData: React.Dispatch<React.SetStateAction<number[]>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setAllTasks?: React.Dispatch<any>
) => {
  setIsLoading(true);

  onAuthStateChanged(auth, (user) => {
    if (user) {
      let id = user.uid;

      const q = query(
        collection(database, "users"),
        where("id", "==", `${id}`)
      );

      onSnapshot(q, async (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let user = doc.data();

          const q = query(
            collection(database, "organisations"),
            where("id", "==", `${user.organisationId}`)
          );

          onSnapshot(q, async (querySnapshot) => {
            querySnapshot.forEach((obj) => {
              let organisation = obj.data();

              let creator: string = organisation.creator;
              let members: member[] = organisation.members;

              const q = query(
                collection(database, "projects/" + projectId + "/tasks/")
              );
              onSnapshot(q, (querySnapshot) => {
                setToDoTasks([]);
                setDoingTasks([]);
                setFinishedTasks([]);
                setData([]);
                setAllTasks !== undefined && setAllTasks([]);
                let total = 0;
                let toDo = 0;
                let doing = 0;
                let finished = 0;
                querySnapshot.forEach((obj) => {
                  let task = obj.data();
                  let taskMembers = task.members;

                  if (task.duplicate !== null) {
                    const startDate = task.start.toDate();
                    const endDate = task.end.toDate();
                    const startHour = startDate.getHours();
                    const startMinute = startDate.getMinutes();
                    const startSecond = startDate.getSeconds();

                    // Créez une copie indépendante de startDate
                    const currentDate = new Date(startDate);

                    while (currentDate <= endDate) {
                      // Créez une copie indépendante de currentDate
                      const currentDateCopy = new Date(currentDate);

                      currentDateCopy.setHours(startHour);
                      currentDateCopy.setMinutes(startMinute);
                      currentDateCopy.setSeconds(startSecond);

                      const finalEndDate = new Date(
                        currentDateCopy.getFullYear(),
                        currentDateCopy.getMonth(),
                        currentDateCopy.getDate(),
                        endDate.getHours(),
                        endDate.getMinutes(),
                        endDate.getSeconds()
                      );

                      const data = {
                        id: task.id,
                        description: task.description,
                        location: task.location,
                        title: task.name,
                        start: currentDateCopy,
                        end: finalEndDate,
                        duplicate: task.duplicate,
                      };

                      setAllTasks !== undefined &&
                        setAllTasks((old: any) => [...old, data]);

                      currentDate.setDate(currentDate.getDate() + 1);
                    }
                  } else if (task.duplicate === null) {
                    const data = {
                      id: task.id,
                      description: task.description,
                      location: task.location,
                      title: task.name,
                      start: timestampToDate(task.start),
                      end: timestampToDate(task.end),
                      duplicate: task.duplicate,
                    };
                    setAllTasks !== undefined &&
                      setAllTasks((old: any) => [...old, data]);
                  }

                  total++;

                  if (task !== undefined) {
                    if (task.status === "toDo") {
                      if (id === creator || exist(members, id)) {
                        setToDoTasks((old: any) => [...old, task]);
                      } else if (members.some((obj: member) => obj.id === id)) {
                        if (taskMembers.some((obj: any) => obj.id === id)) {
                          setToDoTasks((old: any) => [...old, task]);
                        }
                      }

                      toDo++;
                    }

                    if (task.status === "doing") {
                      if (id === creator || exist(members, id)) {
                        setDoingTasks((old: any) => [...old, task]);
                      } else if (members.some((obj: member) => obj.id === id)) {
                        if (taskMembers.some((obj: any) => obj.id === id)) {
                          setDoingTasks((old: any) => [...old, task]);
                        }
                      }
                      doing++;
                    }

                    if (task.status === "finished") {
                      if (id === creator || exist(members, id)) {
                        setFinishedTasks((old: any) => [...old, task]);
                      } else if (members.some((obj: member) => obj.id === id)) {
                        if (taskMembers.some((obj: any) => obj.id === id)) {
                          setFinishedTasks((old: any) => [...old, task]);
                        }
                      }
                      finished++;
                    }
                  }
                });

                setData([total, toDo, doing, finished]);

                setIsLoading(false);
              });
            });
          });
        });
      });
    }
  });
};

export const deleteTask = async (
  project: DocumentData,
  taskId: string,
  setPopup: React.Dispatch<React.SetStateAction<popup>>,
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const collectionRef = collection(
    database,
    "projects/" + `${project.id}` + "/tasks/"
  );
  await deleteDoc(doc(collectionRef, taskId))
    .then(() => {
      setPopup({
        state: "success",
        text: `La tache a été supprimée.`,
      });
      setIsVisible(true);
    })
    .catch(() => {
      setPopup({
        state: "error",
        text: `Un problème est survenu.`,
      });
      setIsVisible(true);
    });
};

export const modifyTask = async (
  taskId: string,
  project: DocumentData,
  name: string,
  description: string,
  location: string,
  status: string,
  setPopup: React.Dispatch<React.SetStateAction<popup>>,
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  end?: any,
  start?: any,
  duplicate?: object
) => {
  setIsLoading(true);

  let data = {
    name: name,
    description: description,
    location: location,
    status: status,
    finished: status === "finished" ? new Date() : null,
    start: start === undefined ? new Date() : start,
    end: end === undefined ? project.end : end,
    duplicate: duplicate !== undefined ? duplicate : null,
  };

  const collectionRef = collection(
    database,
    "projects/" + project.id + "/tasks/"
  );
  const docRef = doc(collectionRef, taskId);

  updateDoc(docRef, data)
    .then(() => {
      setPopup({
        state: "success",
        text: `Les modifications ont été enregistrées.`,
      });
    })
    .catch((err) => {
      console.error(err);
      setPopup({
        state: "error",
        text: `Un problème est survenu.`,
      });
      setIsVisible(true);
    })
    .finally(() => {
      setIsLoading(false);
      setOpen(false);
    });
};

function deleteObjById(id: string, array: any[]): any[] {
  const index = array.findIndex((objet: any) => objet.id === id);

  if (index !== -1) {
    array.splice(index, 1);
  }

  return array;
}

export const removeFromProject = async (
  userId: string,
  projectId: string,
  setPopup: React.Dispatch<React.SetStateAction<popup>>,
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const docRef = doc(database, "projects", projectId);
  const querySnapshot = await getDoc(docRef);

  if (querySnapshot.exists()) {
    const project = querySnapshot.data();
    let members = project.members;

    const docRef = doc(database, "projects", `${projectId}`);

    await updateDoc(docRef, {
      members: deleteObjById(userId, members),
    })
      .then(() => {
        setPopup({
          state: "success",
          text: `Membre retiré.`,
        });
        setIsVisible(true);

        const q1 = query(
          collection(database, "projects", `${projectId}`, "tasks")
        );

        onSnapshot(q1, async (querySnapshot) => {
          querySnapshot.forEach(async (obj) => {
            let task = obj.data();

            removeFromTask(userId, task.id, projectId, setPopup, setIsVisible);
          });
        });
      })
      .catch(() => {
        setPopup({
          state: "error",
          text: `Une erreur s'est produite`,
        });
        setIsVisible(true);
      });
  }
};

export const removeFromTask = async (
  userId: string,
  taskId: string,
  projectId: string,
  setPopup: React.Dispatch<React.SetStateAction<popup>>,
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const q = query(
    collection(database, "projects", `${projectId}`, "tasks"),
    where("id", "==", `${taskId}`)
  );

  onSnapshot(q, async (querySnapshot) => {
    querySnapshot.forEach(async (obj) => {
      let task = obj.data();
      let members = task.members;

      const collectionRef = collection(
        database,
        "projects/" + `${projectId}` + "/tasks/"
      );
      const docRef = doc(collectionRef, taskId);

      await updateDoc(docRef, {
        members: deleteObjById(userId, members),
      });
      // .then(() => {
      //   setPopup({
      //     state: "success",
      //     text: `Membre retiré`,
      //   });
      //   setIsVisible(true);
      // })
      // .catch(() => {
      //   setPopup({
      //     state: "error",
      //     text: `Une erreur s'est produite`,
      //   });
      //   setIsVisible(true);
      // });
    });
  });
};

export const saveTask = async (
  project: DocumentData,
  name: string,
  description: string,
  location: string,
  status: string,
  setPopup: React.Dispatch<React.SetStateAction<popup>>,
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  end?: any,
  start?: any
) => {
  setIsLoading(true);

  const taskId = uuid();
  let data = {
    id: taskId,
    name: name,
    description: description,
    location: location,
    members: [],
    teams: [],
    status: status,
    finished: status === "finished" ? new Date() : null,
    projectId: project.id,
    created: new Date(),
    start: Number.isNaN(start.seconds) ? new Date() : start,
    end: Number.isNaN(end.seconds) ? project.end : end,
    duplicate: null,
  };

  const collectionRef = collection(
    database,
    "projects/" + `${project.id}` + "/tasks/"
  );
  const docRef = doc(collectionRef, taskId);

  await setDoc(docRef, data)
    .then(() => {
      setPopup({
        state: "success",
        text: `Tache créée.`,
      });
    })
    .catch(() => {
      setPopup({
        state: "error",
        text: `Un problème est survenu.`,
      });
      setIsVisible(true);
    })
    .finally(() => {
      setIsLoading(false);
      setOpen(false);
    });
};

export const assignUserToTask = (
  id: string,
  project: DocumentData,
  task: DocumentData,
  setSelected?: React.Dispatch<React.SetStateAction<any[]>>,
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const q = query(collection(database, "users"), where("id", "==", `${id}`));

  onSnapshot(q, async (querySnapshot) => {
    querySnapshot.forEach((obj) => {
      let user = obj.data();

      let data = {
        id: user.id,
        finished: false,
        name: user.name,
        completed: false,
        email: user.email,
        added: new Date(),
        picture: user.picture,
        nickname: user.nickname,
      };

      const collectionRef = collection(
        database,
        "projects/" + `${project.id}` + "/tasks/"
      );

      const docRef = doc(collectionRef, task.id);

      updateDoc(docRef, {
        members: arrayUnion(data),
      })
        .then(() => {
          sendEmailToPerson(id, "added-to-task", {
            reason: "added-to-task",
            taskName: task.name,
          });

          setOpen && setOpen(false);
          setSelected && setSelected([]);
        })
        .catch(() => {
          setOpen && setOpen(false);
          setSelected && setSelected([]);
        });
    });
  });
};

export const changeTaskStatus = (
  project: DocumentData,
  task: DocumentData,
  status: string
) => {
  if (status === "finished") {
    console.error(
      "This function cannot be used to change task status to 'finished'. Please use 'markAsFinished' function."
    );
  } else {
    const collectionRef = collection(database, "projects", project.id, "tasks");

    const docRef = doc(collectionRef, task.id);

    console.log("Status changed");

    updateDoc(docRef, {
      status: status,
    });
  }
};

export const markAsFinished = (
  projectId: string,
  taskId: string,
  setPopup?: React.Dispatch<React.SetStateAction<popup>>,
  setIsVisible?: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const collectionRef = collection(
    database,
    "projects/" + `${projectId}` + "/tasks/"
  );

  const docRef = doc(collectionRef, taskId);

  updateDoc(docRef, {
    status: "finished",
    finished: new Date(),
  })
    .then(() => {
      setPopup &&
        setPopup({
          state: "success",
          text: "Marqué comme terminé !",
        });
      setIsVisible && setIsVisible(true);
    })
    .catch(() => {
      setPopup &&
        setPopup({
          state: "error",
          text: "Une erreur s'est porduite.",
        });
      setIsVisible && setIsVisible(true);
    });
};

export const assignTeamToTask = (
  teamId: string,
  project: DocumentData,
  task: DocumentData,
  setSelected?: React.Dispatch<React.SetStateAction<any[]>>,
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const q = query(
    collection(database, "teams"),
    where("id", "==", `${teamId}`)
  );

  onSnapshot(q, async (querySnapshot) => {
    querySnapshot.forEach((obj) => {
      let team = obj.data();
      const members = team.members;

      const collectionRef = collection(
        database,
        "projects/" + `${project.id}` + "/tasks/"
      );

      const docRef = doc(collectionRef, task.id);

      updateDoc(docRef, {
        teams: arrayUnion({
          id: team.id,
          name: team.name,
        }),
      }).then(() => {
        for (const member of members) {
          if (setSelected && setOpen) {
            assignUserToTask(member.id, project, task, setSelected, setOpen);
          } else {
            assignUserToTask(member.id, project, task);
          }
        }
      });
    });
  });
};

type member = {
  id: string;
  name: string;
  picture: string;
  nickname: string;
  email: string;
  role: string;
};

export const getTaskMembers = (
  taskId: string,
  project: DocumentData,
  setMembers: React.Dispatch<React.SetStateAction<member[] | undefined>>
) => {
  const q = query(
    collection(database, "projects", `${project.id}`, "tasks"),
    where("id", "==", `${taskId}`)
  );

  onSnapshot(q, async (querySnapshot) => {
    setMembers([]);
    querySnapshot.forEach((obj) => {
      let task = obj.data();
      let members = task.members;

      for (const member of members) {
        setMembers((old: any) => [...old, member]);
      }
    });
  });
};

export const getTeamUsers = (
  id: string,
  setMembers: React.Dispatch<React.SetStateAction<member[] | undefined>>
) => {
  const q = query(collection(database, "teams"), where("id", "==", `${id}`));

  onSnapshot(q, async (querySnapshot) => {
    querySnapshot.forEach((obj) => {
      let team = obj.data();
      let members = team.members;

      for (const member of members) {
        setMembers((old: any) => [...old, member]);
      }
    });
  });
};

export const addUserToProject = (
  userId: string,
  project: DocumentData,
  setSelected?: React.Dispatch<React.SetStateAction<any[]>>,
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const q = query(
    collection(database, "users"),
    where("id", "==", `${userId}`)
  );

  onSnapshot(q, async (querySnapshot) => {
    querySnapshot.forEach((obj) => {
      let user = obj.data();

      let data = {
        id: user.id,
        finished: false,
        name: user.name,
        email: user.email,
        added: new Date(),
        picture: user.picture,
        nickname: user.nickname,
        role: "Membre",
      };

      const docRef = doc(database, "projects", `${project.id}`);

      updateDoc(docRef, {
        members: arrayUnion(data),
      })
        .then(() => {
          setOpen && setOpen(false);
          setSelected && setSelected([]);
          sendEmailToPerson(userId, "added-to-project", {
            reason: "added-to-project",
            projectName: project.name,
          });
        })
        .catch(() => {
          setOpen && setOpen(false);
          setSelected && setSelected([]);
        });
    });
  });
};

export const changeMemberRole = (
  id: string,
  project: DocumentData,
  role: string,
  setPopup: React.Dispatch<React.SetStateAction<popup>>,
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>
) => {
  let members = project.members;

  // eslint-disable-next-line array-callback-return
  members = members.map((obj: any) => {
    if (obj.id === id) {
      return { ...obj, role: role };
    }
    return obj; // Ajoutez cette ligne pour retourner l'objet original si la condition est fausse
  });

  const docRef = doc(database, "projects", `${project.id}`);
  setDoc(docRef, { members: members }, { merge: true })
    .then(() => {
      sendEmailToPerson(id, "promoted-manager-org", {
        reason: "promoted-manager-org",
        projectName: project.name,
      });

      setPopup({
        state: "success",
        text: "Role modifié !",
      });
      setIsVisible(true);
    })
    .catch(() => {
      setPopup({
        state: "error",
        text: "Une erreur s'est produite.",
      });
      setIsVisible(true);
    });
};

export const addTeamToProject = (
  teamId: string,
  project: DocumentData,
  setSelected?: React.Dispatch<React.SetStateAction<any[]>>,
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const q = query(
    collection(database, "teams"),
    where("id", "==", `${teamId}`)
  );

  onSnapshot(q, async (querySnapshot) => {
    querySnapshot.forEach((obj) => {
      let team = obj.data();
      const members = team.members;

      const docRef = doc(database, "projects", `${project.id}`);

      updateDoc(docRef, {
        teams: arrayUnion({
          id: team.id,
          name: team.name,
        }),
      }).then(() => {
        for (const member of members) {
          if (setSelected && setOpen) {
            addUserToProject(member.id, project, setSelected, setOpen);
          } else {
            addUserToProject(member.id, project);
          }
        }
      });
    });
  });
};

// const collectionRef = collection(
//   database,
//   // eslint-disable-next-line no-useless-concat
//   "restaurants/" + `${restaurantID}` + "/waiterTasks/"
// );
// const docRef = doc(collectionRef, uuid());
// const documentID = docRef.id;

// let token = tokenCreator();

// await setDoc(docRef, {
//   id: documentID,
//   served: false,
//   restaurantID: restaurantID,
//   token: token,
//   formulasID: formulasID,
//   clientID: id,
// });
