import React, { useRef, useEffect, useMemo, useCallback } from "react";
import {
  Marker,
  GoogleMap,
  useLoadScript,
  Libraries,
} from "@react-google-maps/api";
import { GeoPoint } from "firebase/firestore";

interface Props {
  open: boolean;
  data: GeoPoint;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const librairies = ["places"] as Libraries;

function MapViewer({ open, setOpen, data }: Props) {
  const mapRef = useRef();
  const divRef = useRef<HTMLDivElement>(null);

  const onLoad = useCallback((map: any) => (mapRef.current = map), []);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyB-sqdG_8f_rhghXPFr2rg-T5D-VTX2_3w",
    libraries: librairies,
  });

  const options = useMemo(
    () => ({
      mapId: "676d78e02d8a6eb1",
      disableDefaultUI: true,
      clickableIcons: false,
    }),
    []
  );

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        divRef.current &&
        event.target instanceof Node &&
        !divRef.current.contains(event.target)
      ) {
        setOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [divRef, setOpen]);

  return (
    <div
      className={`fixed top-0 left-0 w-full h-[100vh] z-[1002] 
    ${open ? "show-window" : "hide-window"}
      `}
    >
      <div className="abolsute w-full min-h-[100vh] z-[900] backdrop-blur-[1.5px] bg-[#e2e2e233]"></div>
      <div
        ref={divRef}
        className={`members-handle blur-bg z-[999] overflow-hidden overflow-y-auto fixed bottom-0 md:top-[50%] md:left-[50%] w-full h-[80%] md:w-[45%] md:h-[60%] md:max-w-[475px] md:max-h-[450px] md:translate-x-[-50%] md:translate-y-[-50%] flex items-center justify-center bg-white custom-shadow rounded-[5px]`}
      >
        <div className="for-members-handle overflow-auto relative flex items-center justify-start flex-col w-full h-full">
          {isLoaded && (
            <GoogleMap
              zoom={12}
              center={{ lat: data.latitude, lng: data.longitude }}
              options={options}
              onLoad={onLoad}
              mapContainerClassName="w-full h-full"
            >
              {data && (
                <Marker
                  position={{ lat: data.latitude, lng: data.longitude }}
                />
              )}
            </GoogleMap>
          )}
        </div>
      </div>
    </div>
  );
}

export default MapViewer;
