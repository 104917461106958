import React, { useState, useRef, useEffect } from "react";
import SwitchForChat from "./SwitchForChat";
import Messages from "./Assets/Messages";
import Proofs from "./Assets/Proofs";
import { isUserProofed } from "../Backend/Projects/handleProject";
import { sendMessage, fetchMessages } from "../Backend/Chat/handleChat";
import "../index.css";
import Loader from "./Loader";
import { DocumentData } from "firebase/firestore";

interface Props {
  open: boolean;
  admin: boolean;
  selected: string;
  task: DocumentData;
  project: DocumentData;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSelected: React.Dispatch<React.SetStateAction<string>>;
}

function Chat({
  project,
  task,
  open,
  setOpen,
  admin,
  selected,
  setSelected,
}: Props) {
  const divRef = useRef<HTMLDivElement>(null);
  const messageContainerRef = useRef<HTMLDivElement>(null);

  const [media, setMedia] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [proofs, setProofs] = useState<DocumentData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isProofed, setIsProofed] = useState<boolean>(false);
  const [results, setResults] = useState<DocumentData[]>([]);
  const [discussions, setDiscussions] = useState<DocumentData[]>([]);
  const [justifications, setJustifications] = useState<DocumentData[]>([]);

  useEffect(() => {
    isUserProofed(task.members, setIsProofed);
  }, [task]);

  const send = () => {
    if (message !== "" && media !== "") {
      sendMessage(
        project,
        task.id,
        selected,
        task,
        admin,
        "both",
        message,
        media
      );
      setMedia("");
      setMessage("");
    } else {
      if (message !== "") {
        sendMessage(
          project,
          task.id,
          selected,
          task,
          admin,
          "message",
          message
        );
        setMessage("");
      }

      if (media !== "") {
        sendMessage(
          project,
          task.id,
          selected,
          task,
          admin,
          "media",
          "",
          media
        );
        setMedia("");
      }
    }
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (media !== "") return;

    const file = e.target.files && e.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        if (typeof reader.result === "string") {
          setMedia(reader.result);
        }
      };

      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    setMedia("");
    setMessage("");
  }, [selected]);

  useEffect(() => {
    setProofs([]);
    setResults([]);
    fetchMessages(task.id, project, setProofs, setResults, setIsLoading);
  }, [project, task.id]);

  useEffect(() => {
    setDiscussions([]);

    if (results.length > 0)
      setDiscussions(
        results.slice().sort((a, b) => {
          return (
            a.timeStamp.seconds * 1000 +
            a.timeStamp.nanoseconds / 1000000 -
            (b.timeStamp.seconds * 1000 + b.timeStamp.nanoseconds / 1000000)
          );
        })
      );
  }, [results]);

  useEffect(() => {
    setJustifications([]);

    if (proofs.length > 0)
      setJustifications(
        proofs.slice().sort((a, b) => {
          return (
            a.timeStamp.seconds * 1000 +
            a.timeStamp.nanoseconds / 1000000 -
            (b.timeStamp.seconds * 1000 + b.timeStamp.nanoseconds / 1000000)
          );
        })
      );
  }, [proofs]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        divRef.current &&
        event.target instanceof Node &&
        !divRef.current.contains(event.target)
      ) {
        setOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [divRef, setOpen]);

  useEffect(() => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop =
        messageContainerRef.current.scrollHeight;
    }
  }, [discussions, proofs, selected]);

  return (
    <div
      className={`w-full min-h-[100vh] ${
        open ? "show-window" : "hide-window"
      } fixed top-0 left-0 z-[1002]`}
    >
      <div className="fixed w-full h-[100vh] backdrop-blur-[1.5px] bg-[#e2e2e233]"></div>
      <div
        ref={divRef}
        className="create-team overflow-hidden pt-[0.5em] z-[1001] pb-[70px] fixed bottom-0 md:top-[50%] md:left-[50%] w-full min-w-full md:min-w-0 h-[75%] md:w-[45%] max-w-[400px] md:h-[90%] md:max-h-[600px] md:translate-x-[-50%] md:translate-y-[-50%] flex items-center justify-center bg-white custom-shadow rounded-[12px]"
      >
        <div className="bottom-buttons z-[9999] fixed flex items-center justify-center bg-gray-100 border-t-[1px] border-gray-300 bottom-0 w-full h-[55px] py-[10px]">
          <div className="w-[95%] h-auto flex items-center justify-between z-[9999]">
            {media !== "" && (
              <div className="show-media custom-shadow translate-y-[-88px] absolute top-0 right-[10px] w-[120px] h-[80px] rounded-[8px] custom-border">
                <img
                  src={media}
                  alt="media"
                  className="w-full h-full object-cover rounded-[8px]"
                />
              </div>
            )}
            <div className="conversation custom-border bg-white overflow-hidden rounded-[200px] max-w-[75%] w-[75%] min-h-[35px] h-auto flex items-center justify-center">
              <input
                disabled={(selected === "Justificatifs" && !admin) || isLoading}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                type="text"
                placeholder={
                  selected === "Justificatifs" && !admin
                    ? "Seulement les admins"
                    : "Ecrivez un message"
                }
                className={`${
                  selected === "Justificatifs" && !admin && "placeholder:italic"
                } disabled:bg-white w-[90%] max-w-[90%] break-words h-auto min-h-full placeholder:text-[14px] border-none outline-none`}
              />
            </div>

            <div className="actions relative flex items-center justify-between w-auto h-full">
              {media !== "" ? (
                <div
                  onClick={() => setMedia("")}
                  className="w-[35px] relative custom-border bg-red-500 rounded-full h-[35px] flex items-center justify-center mr-[7px] cursor-pointer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-x w-[25px] h-[25px] text-white"
                    viewBox="0 0 16 16"
                  >
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                  </svg>
                </div>
              ) : (
                <label
                  // onClick={verifySection}
                  htmlFor="file-upload"
                  className="w-[35px] relative custom-border bg-white rounded-full h-[35px] flex items-center justify-center mr-[7px] cursor-pointer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-[20px] h-[20px]"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M18.375 12.739l-7.693 7.693a4.5 4.5 0 01-6.364-6.364l10.94-10.94A3 3 0 1119.5 7.372L8.552 18.32m.009-.01l-.01.01m5.699-9.941l-7.81 7.81a1.5 1.5 0 002.112 2.13"
                    />
                  </svg>
                </label>
              )}
              <input
                type="file"
                id="file-upload"
                className="hidden"
                disabled={selected === "Justificatifs" && isProofed && !admin}
                onChange={(e) => handleFileUpload(e)}
              />
              <button
                onClick={send}
                disabled={
                  (message.length === 0 && media === "") ||
                  (selected === "Justificatifs" && isProofed && !admin)
                }
                className="w-[35px] duration-200 disabled:opacity-70 rotate-[-90deg] rounded-full h-[35px] flex items-center justify-center bg-blue-600 text-white"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-[20px] h-[20px]"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div className="for--create-team relative overflow-y-auto z-0 flex items-center justify-start flex-col w-full h-full">
          <div className="choice w-[90%] h-auto">
            <SwitchForChat
              open={true}
              selected={selected}
              setSelected={setSelected}
            />
          </div>
          <div
            ref={messageContainerRef}
            className="content scroll-smooth w-[90%] flex items-center justify-center h-full max-h-full overflow-y-scroll scrollbar-hide mt-[1em]"
          >
            {isLoading && <Loader />}

            {selected === "Chat" && !isLoading && (
              <Messages project={project} task={task} messages={discussions} />
            )}
            {selected === "Justificatifs" && !isLoading && (
              <Proofs
                task={task}
                admin={admin}
                project={project}
                messages={justifications}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Chat;
