import React, { useEffect, useState } from "react";
import Message from "./Message";
import {
  markMessageAsSeen,
  renderNotifications,
} from "../Backend/Mails/handleNotifications";
import "../index.css";
import Loader from "./Loader";
import { DocumentData } from "firebase/firestore";

interface Props {
  openFromSection?: boolean;
}

function Notification({ openFromSection }: Props) {
  const [nonReaded, setNonReaded] = useState<any>(0);
  const [disable, setDisable] = useState<boolean>(false);
  const [results, setResults] = useState<DocumentData>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<DocumentData>([]);

  useEffect(() => {
    renderNotifications(setResults, setIsLoading);
  }, []);

  useEffect(() => {
    setNotifications([]);

    if (results.length > 1) {
      setNotifications(
        results.sort((a: DocumentData, b: DocumentData) => {
          const timeStampA =
            a.date.seconds * 1000 + a.date.nanoseconds / 1000000;
          const timeStampB =
            b.date.seconds * 1000 + b.date.nanoseconds / 1000000;

          return timeStampB - timeStampA;
        })
      );
    } else if (results.length === 1) {
      setNotifications([results[0]]);
    }
  }, [results]);

  useEffect(() => {
    setNonReaded(0);

    notifications.forEach((obj: any) => {
      if (!obj.read) {
        setNonReaded((current: any) => current + 1);
      }
    });
  }, [notifications]);

  useEffect(() => {
    const el = document.querySelector(".general-section") as HTMLDivElement;
    let left = el.offsetLeft;
    let width = el.offsetWidth;

    let line = document.getElementById("line-progress");

    if (line) {
      line.style.left = `${left}px`;
      line.style.width = `${width}px`;
    }
  }, []);

  const markAllAsSeen = () => {
    notifications.forEach((notification: DocumentData) => {
      markMessageAsSeen(notification.id);
    });

    setDisable(true);
  };

  return (
    <div
      className={`notification ${
        openFromSection === null ||
        (!openFromSection &&
          "md:mr-[1em] md:w-[24%] h-auto mb-[1em] mx-[0.5em]")
      } ${
        openFromSection && openFromSection && "w-full h-auto"
      } custom-shadow rounded-[10px] flex items-center justify-center snap-center min-w-[97.5%] md:min-w-0 py-[0.7em]`}
    >
      <div className="for-notification flex items-start justify-start flex-col w-full h-auto">
        {/* HEAD */}
        <div className="notification-head pb-[0.5em] relative custom-shadow-s border-b-[1.5px] border-[#e9e9e9] flex items-center justify-center w-full h-auto">
          <div
            id="line-progress"
            className="line-progress absolute bottom-0 h-[2.5px] rounded-full bg-[#333]"
          ></div>
          <div className="for--notification-head flex items-start justify-start flex-col w-[90%] h-auto">
            <div className="name-button mb-[0.7em] flex items-center justify-between w-full h-auto">
              <label className="text-[20px] font-[500] text-[#333]">
                Notifications
              </label>

              <button
                disabled={disable}
                onClick={markAllAsSeen}
                className="bg-transparent border-none text-[13px] text-gray-400 hover:text-blue-400 hover:underline"
              >
                Marquer tout comme lu
              </button>
            </div>

            <div className="navigation-bar flex items-start justify-start w-full h-auto">
              <button className="common-for-navigation-bar cursor-pointer general-section flex items-center justify-start w-auto h-auto">
                <label className="text-[#333] cursor-pointer font-[500] text-[14px] text-[400] mr-[6px]">
                  Général
                </label>
                <div className="msgs-value flex items-center justify-center w-[26px] h-[20px] rounded-full bg-red-500 text-white text-[12px] font-[500]">
                  <span>{nonReaded}</span>
                </div>
              </button>
            </div>
          </div>
        </div>

        <div className="all-messages scrollbar-hide flex items-start justify-start flex-col w-full h-auto max-h-[400px] overflow-y-auto">
          {isLoading && (
            <div className="flex items-center justify-center w-full py-[1.5em]">
              <Loader />
            </div>
          )}
          {!isLoading && notifications.length === 0 && (
            <div className="flex items-center justify-center w-full py-[1.25em]">
              <label className="text-[13px] text-gray-400">
                Aucune notification.
              </label>
            </div>
          )}
          {!isLoading &&
            notifications.length !== 0 &&
            notifications?.map((notification: any) => (
              <Message {...notification} key={notification.id} />
            ))}
        </div>
      </div>
    </div>
  );
}

export default Notification;
