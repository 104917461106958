import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCH_3XXIevnw-3F1dP423CY5OCeBvKACKQ",
  authDomain: "teamask-e9bcd.firebaseapp.com",
  projectId: "teamask-e9bcd",
  storageBucket: "teamask-e9bcd.appspot.com",
  messagingSenderId: "512185217374",
  appId: "1:512185217374:web:3b57db5922b7e3f5cedfcf",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const database = getFirestore(app);
