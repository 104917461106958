import React, { useRef, useState } from "react";

interface Props {
  setValue: React.Dispatch<React.SetStateAction<string>>;
}

function Picture({ setValue }: Props) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [image, setImage] = useState<string | null>(null);

  const handleDivClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files && event.target.files[0];

    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64String = e.target?.result as string; // Obtenez la représentation base64
        setImage(base64String);
        setValue(base64String);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  return (
    <div className="import-picture flex items-center justify-center flex-col w-auto h-auto">
      <div
        onClick={handleDivClick}
        className="for--import-picture hover:cursor-pointer flex items-center justify-center w-[200px] h-[200px] sm:w-[250px] sm:h-[250px] rounded-[50%] border-dashed border-[4px] border-blue-500 bg-[#3E404A]"
      >
        <input
          ref={fileInputRef}
          type="file"
          accept=".jpg,.jpeg,.png"
          style={{ display: "none" }}
          onChange={handleFileChange}
        />

        {image === null ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-person-bounding-box hover:cursor-pointer w-[75px] h-[75px] sm:w-[100px] sm:h-[100px] text-white"
            viewBox="0 0 16 16"
          >
            <path d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1h-3zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5zM.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5z" />
            <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm8-9a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
          </svg>
        ) : (
          <div className="w-[95%] h-[95%] flex items-center justify-center overflow-hidden rounded-[50%]">
            <img src={image} alt="profil" />
          </div>
        )}
      </div>
    </div>
  );
}

export default Picture;
