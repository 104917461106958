import { Timestamp } from "firebase/firestore";

export type TeamMember = {
  id: string;
  name: string;
  picture: string;
  nickname: string;
  email: string;
  role: string;
  added: Timestamp;
};

export type Team = {
  id: string;
  name: string;
};

export type TaskMember = {
  id: string;
  name: string;
  nickname: string;
  picture: string;
  email: string;
  added: Date;
  finished: boolean;
};

export type TaskType = {
  id: string;
  name: string;
  started: Timestamp;
  description: string;
  location: string;
  members: TaskMember[];
  teams?: Team[];
};

export type member = {
  id: string;
  name: string;
  picture: string;
  nickname: string;
  email: string;
  role: string;
  added: Timestamp;
};

export const all = "#7C88C3";
export const toDo = "#f97316";
export const doing = "#478BF2";
export const finished = "#51B069";
