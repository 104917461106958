import { useState, useEffect, useRef } from "react";
import NavBar from "./Components/NavBar";
import loadable from "@loadable/component";
import Notification from "./Components/Notification";
import "./index.css";

const LoadableDashboard = loadable(() => import("./Dashboard/Dashboard"));
const LoadableProjects = loadable(() => import("./Projects/Projects"));
const LoadableTeam = loadable(() => import("./TeamManagement/Team"));
const LoadableBusiness = loadable(() => import("./Business/Business"));
const LoadableSettings = loadable(() => import("./Settings/Setting"));

function App() {
  const [open, setOpen] = useState(false);
  const [current, setCurrent] = useState("");
  const divRef = useRef<HTMLDivElement>(null);
  const links = ["dashboard", "business", "projects", "teams", "settings"];

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        divRef.current &&
        event.target instanceof Node &&
        !divRef.current.contains(event.target)
      ) {
        setOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [divRef, setOpen]);

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [open]);

  useEffect(() => {
    const parts = document.location.href.split("/");

    if (links.includes(parts[3])) {
      setCurrent(parts[3]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document.location.href]);

  useEffect(() => {
    setOpen(false);
  }, [current]);

  const renderComponent = () => {
    switch (current) {
      case "dashboard":
        return <LoadableDashboard />;
      case "business":
        return <LoadableBusiness />;
      case "projects":
        return <LoadableProjects />;
      case "teams":
        return <LoadableTeam />;
      case "settings":
        return <LoadableSettings />;
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen">
      <NavBar
        setVal={setCurrent}
        currentSection={current}
        setOpenNotifications={setOpen}
      />

      {open && current !== "projects" && (
        <div className="fixed z-[99999] w-full h-[100vh] backdrop-blur-[1.5px] bg-[#e2e2e233]"></div>
      )}

      {current !== "projects" && (
        <div
          ref={divRef}
          className={`fixed py-[1em] px-[0.75em] flex items-end justify-start flex-col ${
            open ? "right-0" : "right-[-100%] sm:right-[-400px]"
          } duration-200 top-0 right-0 z-[99999] w-full sm:w-[400px] h-full bg-white custom-shadow`}
        >
          <div
            className="flex cursor-pointer mb-[1.5em] items-center justify-center w-[30px] h-[30px] rounded-full hover:custom-shadow"
            onClick={(e) => setOpen(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-[17px] h-[17px] cursor-pointer"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>
          <Notification openFromSection={true} />
        </div>
      )}

      {renderComponent()}
    </div>
  );
}

export default App;
