import { render } from "@react-email/render";
import Message from "../../EmailTemplates/Message";
import { RecentLogin } from "../../EmailTemplates/RecentLogin";

export const emailTemplates = [
  {
    subject: "Vous venez de créer votre organisation.",
    type: "org-created",
  },
  {
    subject: "Vous avez rejoins une organisation.",
    type: "added-to-org",
  },
  {
    subject: "Vous avez été ajouté(e) à un projet.",
    type: "added-to-project",
  },
  {
    subject: "Vous avez été ajouté(e) à une team.",
    type: "added-to-team",
  },
  {
    subject: "Vous avez été assigné(e) à une tâche.",
    type: "added-to-task",
  },
  {
    subject: "Une tâche vient d'etre justifiée.",
    type: "added-proof",
  },
  {
    subject: "Votre justificatif a été rejété.",
    type: "proof-deleted",
  },
  {
    subject: "Vous avez recu une promotion.",
    type: "promoted-manager-team",
  },
  {
    subject: "Vous avez recu une promotion.",
    type: "promoted-manager-org",
  },
  {
    subject: "Quelqu'un vient de se connecter à votre compte.",
    type: "recent-login",
  },
];

export const returnNotificationBody = (reason: string, params: any) => {
  if (reason === "org-created") {
    return `Vous venez de créer votre organisation "${params.organisationName}".`;
  }

  if (reason === "added-to-org") {
    return `Vous avez rejoins une organisation.`;
  }

  if (reason === "added-to-project") {
    return `${params.username} a été ajouté au projet "${params.projectName}".`;
  }

  if (reason === "added-to-team") {
    return `${params.username} a été ajouté à l'équipe "${params.teamName}".`;
  }

  if (reason === "added-to-task") {
    return `${params.username} a été assigné à la tâche "${params.taskName}"`;
  }

  if (reason === "added-proof") {
    return `${params.username} a fournit son justificatif de la tâche "${params.taskName}" du projet "${params.projectName}".`;
  }

  if (reason === "proof-deleted") {
    return `Le justificatif fournit par ${params.username} pour la tâche "${params.taskName}" du projet "${params.projectName}" a été supprimé.`;
  }

  if (reason === "promoted-manager-team") {
    return `${params.username} a été promu au rang de "Manager" de l'équipe "${params.teamName}".`;
  }

  if (reason === "promoted-manager-org") {
    return `${params.user} a été promu au rang de "Manager" de l'organisation.`;
  }

  return "";
};

export const returnTemplate = (reason: string, params: any): string => {
  let subject: string = "";

  for (const obj of emailTemplates) {
    if (obj.type === reason) {
      subject = obj.subject;
    }
  }

  if (reason === "recent-login") {
    return render(RecentLogin({ ...params, subject }), {
      pretty: true,
    });
  }

  if (reason === "added-to-project") {
    return render(Message({ ...params, subject }), {
      pretty: true,
    });
  }

  if (reason === "added-to-team") {
    return render(Message({ ...params, subject }), {
      pretty: true,
    });
  }

  if (reason === "added-to-task") {
    return render(Message({ ...params, subject }), {
      pretty: true,
    });
  }

  if (reason === "added-proof") {
    return render(Message({ ...params, subject }), {
      pretty: true,
    });
  }

  if (reason === "proof-deleted") {
    return render(Message({ ...params, subject }), {
      pretty: true,
    });
  }

  if (reason === "promoted-manager-team") {
    return render(Message({ ...params, subject }), {
      pretty: true,
    });
  }

  if (reason === "promoted-manager-org") {
    return render(Message({ ...params, subject }), {
      pretty: true,
    });
  }

  if (reason === "recent-login") {
    return render(RecentLogin({ ...params, subject }), {
      pretty: true,
    });
  }

  return "";
};
