import React from "react";
import { DocumentData } from "firebase/firestore";
import Msg from "./Msg";
import Both from "./Both";
import Media from "./Media";

interface Props {
  task: DocumentData;
  project: DocumentData;
  messages: DocumentData[];
}

function Messages({ messages, project, task }: Props) {
  return (
    <div className="w-[98%] h-full flex items-start justify-start flex-col">
      {messages.length === 0 && (
        <div className="w-full h-full flex items-center justify-center">
          <span className="text-[14px] text-gray-400">Aucun message</span>
        </div>
      )}
      {messages.length > 0 &&
        messages.map((obj: any) => {
          if (obj.type === "message") {
            return <Msg key={obj.id} {...obj} task={task} project={project} />;
          } else if (obj.type === "media") {
            return (
              <Media key={obj.id} {...obj} task={task} project={project} />
            );
          } else if (obj.type === "both") {
            return <Both key={obj.id} {...obj} task={task} project={project} />;
          }
        })}
    </div>
  );
}

export default Messages;
