import React, { useState, useEffect } from "react";
import { DocumentData, Timestamp } from "firebase/firestore";
import CustomSelect from "./CustomSelect";
import CalendarData from "./Calendar";
import HandleMembers from "./HandleMembers";
import TimeStamp from "../../Components/TimeStamp";
import CreateProject from "./CreateProject";
import Popup from "../../Components/Popup";
import { deleteProject } from "../../Backend/Projects/handleProject";
import { useNavigate } from "react-router-dom";
import MapViewer from "../../Components/MapViewer";

interface Props {
  admin: boolean;
  tasks: any[];
  project: DocumentData;
  searchTask: string;
  setSearchTask: React.Dispatch<React.SetStateAction<string>>;
}

type popup = {
  text: string;
  state: string;
};

function ProjectHeader({
  project,
  admin,
  tasks,
  searchTask,
  setSearchTask,
}: Props) {
  const navigate = useNavigate();

  const [openC, setOpenC] = useState<boolean>(false);
  const [, setName] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [, setIsLoading] = useState<boolean>(false);
  const [modify, setModify] = useState<boolean>(false);
  const [, setDate] = useState<Timestamp | null>(null);
  const [openMap, setOpenMap] = useState<boolean>(false);
  const [openCalendar, setOpenCalendar] = useState<boolean>(false);
  const [popup, setPopup] = useState<popup>({
    state: "success",
    text: "Invalid error",
  });
  const [isVisible, setIsVisible] = useState<boolean>(false);

  useEffect(() => {
    if (open || openC) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [open, openC]);

  useEffect(() => {
    if (openCalendar) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [openCalendar]);

  useEffect(() => {
    if (openMap) {
      window.document.body.style.overflow = "hidden";
    } else {
      window.document.body.style.overflow = "auto";
    }
  }, [openMap]);

  useEffect(() => {
    if (project !== undefined) {
      setDate(project.created);
      setName(project.creator.name);
    }
  }, [project]);

  const openCreate = () => {
    setModify(true);
    setOpenC(true);
  };

  const deleteP = () => {
    const result = window.confirm(
      "Voulez-vous vraiment supprimer ce projet? Cette action est irréversible."
    );

    if (result) {
      deleteProject(project.id, setIsLoading, setPopup, setIsVisible);
      navigate("/");
    }
  };

  return (
    <div className="project-head relative flex items-center justify-center flex-col w-full h-auto my-[1.25em]">
      <Popup isVisible={isVisible} infos={popup} />
      <HandleMembers
        admin={admin}
        members={project.members}
        project={project}
        teams={project.teams}
        open={open}
        setOpen={setOpen}
      />

      <CalendarData
        tasks={tasks}
        admin={admin}
        project={project}
        open={openCalendar}
        setOpen={setOpenCalendar}
      />

      <CreateProject
        open={openC}
        setOpen={setOpenC}
        modify={modify}
        setModify={setModify}
        project={project}
      />
      <MapViewer
        open={openMap}
        setOpen={setOpenMap}
        data={project.location.position}
      />

      <div className="for--project-head flex items-center justify-start flex-col w-full h-auto">
        {/* INFOS */}
        <div className="project-head-infos z-10 flex items-center justify-center flex-col w-[92.5%] h-auto">
          <div className="for--project-head-infos flex md:items-end md:justify-between w-full h-auto">
            {/* TXT */}

            <div className="project-head-infos-txt flex items-start justify-start flex-col w-auto h-auto">
              <button
                onClick={(e) => navigate("/projects")}
                className="mb-2 flex items-center justify-center duration-300 hover:opacity-[0.8] rounded-full gap-2 bg-gray-200 px-3 h-[45px] text-white"
              >
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-arrow-left-short w-[22px] h-[22px] fill-[#222]"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5"
                    />
                  </svg>
                </span>
                <span className="text-[#222] font-[500]">
                  Revenir en arriere
                </span>
              </button>
              {/* NAME */}
              <div className="project-name-actions-date flex items-start justify-start flex-col w-auto h-auto">
                <div className="project-name-action flex items-center justify-start w-auto">
                  <div className="project-name text-[25px] text-black font-[500]">
                    <label>{project.name}</label>
                  </div>

                  {admin && (
                    <div className="project-actions flex items-center justify-start ml-[15px]">
                      <button
                        onClick={openCreate}
                        className="edit-btn hover:cursor-pointer hover:text-blue-500 flex items-center justify-center w-auto h-auto"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="20"
                          viewBox="0 -960 960 960"
                          width="20"
                        >
                          <path d="M204-204h59l336-336-59-59-336 336v59Zm573-394L598-777l49-49q25-25 59.5-25t59.5 25l60 60q24 24 24 59.5T826-647l-49 49Zm-59 59L299-120H120v-179l419-419 179 179Zm-148-30-30-30 59 59-29-29Z" />
                        </svg>
                      </button>
                      <button
                        onClick={deleteP}
                        className="remove-btn hover:cursor-pointer hover:text-red-500 flex items-center justify-center w-auto h-auto ml-[7px]"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="20"
                          viewBox="0 -960 960 960"
                          width="20"
                        >
                          <path d="M312-144q-29.7 0-50.85-21.15Q240-186.3 240-216v-480h-48v-72h192v-48h192v48h192v72h-48v479.566Q720-186 698.85-165 677.7-144 648-144H312Zm336-552H312v480h336v-480ZM384-288h72v-336h-72v336Zm120 0h72v-336h-72v336ZM312-696v480-480Z" />
                        </svg>
                      </button>
                    </div>
                  )}
                </div>

                <small className="text-[13px] text-gray-500 font-[400] mt-[3px]">
                  Crééer le {<TimeStamp timestamp={project.created} />} par{" "}
                  {project.creator.name}
                </small>
              </div>

              <div className="project-description my-[1em] text-[14px] text-gray-400 font-[400] max-w-[65%]">
                <p>{project.description}</p>
              </div>

              {/* FEATURES */}

              <div className="project-feature flex-wrap flex items-center justify-start max-w-[400px] w-auto h-auto">
                <div className="common-for-feature flex items-center justify-start w-auto h-auto m-[8px] ml-[0px]">
                  <div className="feature-icon flex items-center justify-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="w-[20px] h-[20px]"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
                      />
                    </svg>
                  </div>

                  <label className="feature-name text-[14px] ml-[7px] font-[400] text-gray-500">
                    Géré par {project.creator.name}
                    {project.members.length > 0 && (
                      <React.Fragment>
                        {project.members?.map((member: any) => (
                          <span>
                            {member.role === "Manager" && (
                              <span>
                                {", "}
                                {member.name}
                              </span>
                            )}
                          </span>
                        ))}
                      </React.Fragment>
                    )}
                    <span>.</span>
                  </label>
                </div>
                <div className="common-for-feature flex items-center justify-start w-auto h-auto m-[8px] ml-[0px]">
                  <div className="feature-icon flex items-center justify-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="w-[20px] h-[20px]"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                      />
                    </svg>
                  </div>

                  <button
                    onClick={() => setOpenMap(true)}
                    className="feature-name text-[14px] ml-[7px] font-[400] text-gray-500 hover:underline hover:text-blue-500"
                  >
                    {project.location.address}
                  </button>
                </div>
                <div className="common-for-feature flex items-center justify-start w-auto h-auto m-[8px] ml-[0px]">
                  <div className="feature-icon flex items-center justify-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="w-[20px] h-[20px]"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
                      />
                    </svg>
                  </div>

                  <label className="feature-name text-[14px] ml-[7px] font-[400] text-gray-500">
                    {project.members.length} membres
                  </label>
                </div>
              </div>
            </div>

            {/* MEMBERS */}

            <div className="project-handle-members flex items-center justify-center w-auto h-auto">
              <div className="for-project-handle-members flex items-start justify-start flex-col w-full h-auto">
                <label className="text-[13px] text-gray-400 font-[400] mb-[8px]">
                  Membres
                </label>

                <div className="handle-members-action flex items-center justify-start w-auto h-auto">
                  <div className="members-list flex items-center justify-center w-auto h-auto">
                    <CustomSelect members={project.members} />
                  </div>

                  <button
                    // disabled={!admin}
                    onClick={(e) => setOpen(true)}
                    className="flex items-center justify-center w-auto h-[35px] px-[0.5em] ml-[7px] rounded-[8px] bg-teal-600 text-white"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="w-[20px] h-[20px]"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* ACTIONS */}

        <div className="project-head-actions border-[1.5px] border-[#EEEEF3] py-[6.5px] w-full h-auto flex items-center justify-center mt-[1em]">
          <div className="for-project-head-actions flex items-start justify-between w-[92.5%] h-auto">
            {/* SEARCH */}
            <div className="project-head-action-search-bar flex items-center justify-start w-[75%] h-auto">
              <div className="search-bar border-[1.5px] border-[#dadae0] flex items-center justify-start w-full h-[40px] bg-[#F6F6F6] rounded-[8px] overflow-hidden">
                <div className="search-bar--svg flex items-center justify-center w-[40px] h-full fill-gray-600 text-gray-600">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="w-[15px] h-[15px]"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                    />
                  </svg>
                </div>

                <input
                  type="text"
                  value={searchTask}
                  onChange={(e) => setSearchTask(e.target.value)}
                  className="w-[calc(100%-45px)] h-full border-none outline-none bg-inherit placeholder:text-gray-600 placeholder:text-[14px] text-[16px]"
                  placeholder="Rechercher une tâche."
                />
              </div>
            </div>

            {/* ACTIONS */}
            <div className="project-head-action-actions">
              <div className="for--project-head-action-actions">
                <button
                  onClick={(e) => setOpenCalendar(true)}
                  className="toggle-calendar cursor-pointer text-white font-[500] bg-teal-600 flex items-center justify-center rounded-[8px] px-[6px] border-[1.5px] w-[40px] md:w-auto h-[40px] border-[#EEEEF3]"
                >
                  <label className="text-[14px] hidden md:flex cursor-pointer mr-[7px] text-white">
                    Calendrier
                  </label>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="w-[15px] h-[15px]"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectHeader;
