import React, { useEffect, useState } from "react";
import MoreForTask from "./MoreForTask";
import { DocumentData, Timestamp } from "firebase/firestore";
import { TaskMember, Team } from "../../Interfaces/TaskInterface";
import TimeStamp from "../../Components/TimeStamp";

interface Props {
  project: DocumentData;
  task: DocumentData;
  color: string;
  status: string;
  admin: boolean;
}

function Task({ task, color, project, status, admin }: Props) {
  const [completed, setCompleted] = useState<number>(0);
  const [percentage, setPercentage] = useState<number>(0);

  useEffect(() => {
    setCompleted(0);

    task.members.forEach((obj: any) => {
      if (obj.completed) {
        setCompleted((val) => val + 1);
      }
    });

    setPercentage(Math.trunc((completed * 100) / task.members.length));

    const popupElement = document.querySelector(
      `.fill-bar-${task.id}`
    ) as HTMLElement;
    if (popupElement) {
      popupElement.style.width = `${percentage}%`;
    }
  }, [completed, percentage, task]);

  useEffect(() => {
    const popupElement = document.querySelector(
      `.fill-bar-${task.id}`
    ) as HTMLElement;
    if (popupElement) {
      popupElement.style.backgroundColor = color;
    }
  });

  return (
    <div className="task relative z-[auto] flex items-center justify-center w-full h-auto rounded-[6px] mt-[7.5px] border-[1px] border-[#dadae0] bg-[#F6F6F6]">
      <div className="for-task flex items-start justify-start flex-col w-[92.5%] h-auto py-[6px]">
        {/* NAME */}
        <div className="task-name flex items-start justify-between w-full h-auto">
          <div className="flex items-start justify-start flex-col w-auto h-auto">
            <label className="text-[14px] font-[600] text-black">
              {task.name}
            </label>
            <small className="text-[11px] font-[400] text-gray-400 mt-[1.75px]">
              Commencé le <TimeStamp timestamp={task.created} />
            </small>
          </div>

          <div className="task-name-more-option flex items-center justify-center hover:cursor-pointer">
            <MoreForTask
              task={task}
              admin={admin}
              status={status}
              project={project}
            />
          </div>
        </div>
        {/* DESCRIPTION */}
        <div className="task-description flex items-start justify-start w-auto h-auto mt-[6px] max-w-full">
          <p className="text-[12.5px] text-gray-500 font-[400] text-ellipsis overflow-hidden">
            {task.description}
          </p>
        </div>

        {/* COMPLETED */}
        <div className="task-completion flex items-center justify-center w-full h-auto py-[0.5em] mt-[6px] rounded-[5px] border-[1px] border-[#dadae0] bg-white">
          <div className="flex items-center justify-start flex-col w-[95%]">
            <div className="task-completion-head flex items-center justify-between w-full h-auto">
              <div className="flex items-center justify-start w-auto h-auto">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-[14px] h-[14px]"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
                  />
                </svg>

                <label className="text-[12.5px] font-[500] text-[#303030] ml-[5px]">
                  Completées
                </label>
              </div>

              <label className="text-[12.5px] font-[400] text-[#333]">
                {completed}/{task.members.length}
              </label>
            </div>

            <div className="progress-bar relative z-[1] flex items-center justify-start mt-[6px] w-full h-[6px] rounded-[30px] bg-[#e4e4e4]">
              <div
                className={`fill-bar-${task.id} absolute h-full rounded-[30px] bg-orange-500`}
              ></div>
            </div>
          </div>
        </div>

        {/* SEPARATOR */}
        <div className="w-full border-t-[1px] border-[#dadae0] my-[7.5px]"></div>

        {/* MEMBERS */}
        <div className="task-members mt-[6px] flex items-center justify-start w-full h-auto">
          <label className="text-[12.5px] text-gray-400 font-[400] italic mr-[6px]">
            Géré par {task.members.length} personnes et {task.teams.length}{" "}
            équipes
          </label>
          {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-[15px] h-[15px] cursor-pointer hover:text-blue-500"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
            />
          </svg> */}
        </div>
      </div>
      {task.duplicate !== null && (
        <div className="absolute bottom-0 flex items-center justify-center flex-col w-full h-auto translate-y-[170%]">
          <div className="border-t-[1.5px] border-[#dadae0] w-[90%]"></div>
          <div className="border-t-[1.5px] border-[#dadae0] w-[75%] mt-[2px]"></div>
        </div>
      )}
    </div>
  );
}

export default Task;
