import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";
Chart.register(ArcElement);

const data = {
  labels: ["Toutes les taches", "A faire", "En cours", "Terminées"],
  datasets: [
    {
      label: "Tasks",
      data: [1, 0, 0, 0],
      backgroundColor: ["#e7e7e7", "#f97316", "#478BF2", "#51B069"],
      borderWidth: 0.1,
      hoverOffset: 4,
    },
  ],
  options: {
    tooltips: {
      enabled: true,
    },
  },
};

function ChartLoader() {
  return (
    <div className="flex translate-y-[-1.25em] sticky top-0 rounded-t-none border-t-0 items-center justify-center h-auto snap-center min-w-[97.5%] md:min-w-0 md:w-[24%] rounded-[8px] py-[1em] border-[1px] border-[#dadae0] bg-[#F6F6F6]">
      <div className="flex items-start justify-start flex-col w-[95%] h-auto">
        {/* PROGRESS BAR */}
        <div className="circular-progress-bar flex items-center justify-center w-full h-auto">
          <div className="w-full h-auto">
            <Doughnut width="100%" data={data} />
          </div>
        </div>
        {/* SUMMARY */}
        <div className="tasks-summary flex items-start justify-start flex-col w-full h-auto mt-[0.25em]">
          <label className="text-[16px] text-[#303030] font-[500] mb-[15px]">
            Taches
          </label>
          <div className="all-summ flex items-start justify-center flex-wrap w-[full] h-auto">
            {/*  */}
            <div className="common-for-detail mb-[12px] flex items-center justify-between w-[95%] h-auto">
              <div className="color- flex items-center justify-start w-auto h-auto">
                <div
                  className={`color bg-all w-[12px] h-[12px] rounded-full mr-[7px]`}
                ></div>
                <label className="text-[14px] text-[#333] font-[500]">
                  Toutes les tâches
                </label>
              </div>

              <div className="percentage text-[13px] text-gray-500 font-[400]">
                <label>100%</label>
              </div>
            </div>
            {/*  */}
            <div className="common-for-detail mb-[12px] flex items-center justify-between w-[95%] h-auto">
              <div className="color- flex items-center justify-start w-auto h-auto">
                <div
                  className={`color bg-toDo w-[12px] h-[12px] rounded-full mr-[7px]`}
                ></div>
                <label className="text-[14px] text-[#333] font-[500]">
                  Tâches à faire
                </label>
              </div>

              <div className="percentage text-[13px] text-gray-500 font-[400]">
                <label>0 %</label>
              </div>
            </div>
            {/*  */}
            <div className="common-for-detail mb-[12px] flex items-center justify-between w-[95%] h-auto">
              <div className="color- flex items-center justify-start w-auto h-auto">
                <div
                  className={`color bg-doing w-[12px] h-[12px] rounded-full mr-[7px]`}
                ></div>
                <label className="text-[14px] text-[#333] font-[500]">
                  Tâches en cours
                </label>
              </div>

              <div className="percentage text-[13px] text-gray-500 font-[400]">
                <label>0 %</label>
              </div>
            </div>
            {/*  */}
            <div className="common-for-detail mb-[12px] flex items-center justify-between w-[95%] h-auto">
              <div className="color- flex items-center justify-start w-auto h-auto">
                <div
                  className={`color bg-finished w-[12px] h-[12px] rounded-full mr-[7px]`}
                ></div>
                <label className="text-[14px] text-[#333] font-[500]">
                  Tâches terminées
                </label>
              </div>

              <div className="percentage text-[13px] text-gray-500 font-[400]">
                <label>0 %</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChartLoader;
