import { Body } from "@react-email/body";
import { Button } from "@react-email/button";
import { Container } from "@react-email/container";
import { Column } from "@react-email/column";
import { Head } from "@react-email/head";
import { Heading } from "@react-email/heading";
import { Html } from "@react-email/html";
import { Img } from "@react-email/img";
import { Preview } from "@react-email/preview";
import { Row } from "@react-email/row";
import { Section } from "@react-email/section";
import { Text } from "@react-email/text";

// import * as React from "react";

interface YelpRecentLoginEmailProps {
  subject: string;
  username?: string;
  loginDate?: Date;
  loginDevice?: string;
  loginLocation?: string;
  loginIp?: string;
}

const baseUrl = process.env.VERCEL_URL
  ? `https://${process.env.VERCEL_URL}`
  : "";

export const RecentLogin = ({
  username,
  subject,
  loginDate,
  loginDevice,
  loginLocation,
  loginIp,
}: YelpRecentLoginEmailProps) => {
  const formattedDate = new Intl.DateTimeFormat("fr", {
    dateStyle: "long",
    timeStyle: "short",
  }).format(loginDate);

  return (
    <Html>
      <Head />
      <Preview>{subject}</Preview>
      <Body style={main}>
        <Container>
          <Section style={logo}>
            <Img src={`${baseUrl}/static/yelp-logo.png`} />
          </Section>

          <Section style={content}>
            <Img
              width={620}
              src="https://img.freepik.com/vecteurs-libre/triangle-rouge-panneau-avertissement-vector-art-illustration_56104-865.jpg?size=626&ext=jpg&ga=GA1.1.229629212.1696869377&semt=sph"
            />

            <Row style={{ ...boxInfos, paddingBottom: "0" }}>
              <Column>
                <Heading
                  style={{
                    fontSize: 32,
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Hey {username},
                </Heading>
                <Heading
                  as="h2"
                  style={{
                    fontSize: 26,
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Nous avons remarqué une nouvelle connexion sur votre compte
                  TeamAsk.
                </Heading>

                <Text style={paragraph}>Date: {formattedDate}</Text>
                <Text style={{ ...paragraph, marginTop: -5 }}>
                  Appareil: {loginDevice}
                </Text>
                <Text style={{ ...paragraph, marginTop: -5 }}>
                  Localisation: {loginLocation}
                </Text>
                <Text
                  style={{
                    color: "rgb(0,0,0, 0.5)",
                    fontSize: 14,
                    marginTop: -5,
                  }}
                >
                  *Localisation approximative basée sur votre adresse IP:
                  {loginIp}
                </Text>

                <Text style={paragraph}>
                  Si c'était vous, merci d'ignorer ce message.
                </Text>
                <Text style={{ ...paragraph, marginTop: -5 }}>
                  Si ce n'était pas vous, merci de nous envoyer un mail afin que
                  nous désactivons momentanément votre compte pour des
                  vérification.
                </Text>
              </Column>
            </Row>
            <Row style={{ ...boxInfos, paddingTop: "0" }}>
              <Column style={containerButton} colSpan={2}></Column>
            </Row>
          </Section>

          {/* <Section style={containerImageFooter}>
            <Img width={620} src={`${baseUrl}/static/yelp-footer.png`} />
          </Section> */}

          <Text
            style={{
              textAlign: "center",
              fontSize: 12,
              color: "rgb(0,0,0, 0.7)",
            }}
          >
            © 2023 | Intellifrica Inc., Casablanca (Maroc), Ouagadougou (Burkina
            Faso), Africa | www.intellifrica.com
          </Text>
        </Container>
      </Body>
    </Html>
  );
};

export default RecentLogin;

const main = {
  backgroundColor: "#fff",
  fontFamily:
    '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif',
};

const paragraph = {
  fontSize: 16,
};

const logo = {
  padding: "30px 20px",
};

const containerButton = {
  display: "flex",
  justifyContent: "center",
  width: "100%",
};

const button = {
  backgroundColor: "#e00707",
  padding: "12px 30px",
  borderRadius: 3,
  color: "#FFF",
  fontWeight: "bold",
  border: "1px solid rgb(0,0,0, 0.1)",
  cursor: "pointer",
};

const content = {
  border: "1px solid rgb(0,0,0, 0.1)",
  borderRadius: "3px",
  overflow: "hidden",
};

const boxInfos = {
  padding: "20px 40px",
};

const containerImageFooter = {
  padding: "45px 0 0 0",
};
