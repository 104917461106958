import { Timestamp } from "firebase/firestore";
import TimeStamp from "./TimeStamp";
import { useInView } from "react-intersection-observer";
import React, { useEffect } from "react";
import { markMessageAsSeen } from "../Backend/Mails/handleNotifications";

interface Props {
  message: string;
  date: Timestamp;
  read: boolean;
  id: string;
}

function Message({ message, date, read, id }: Props) {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 1,
  });

  useEffect(() => {
    if (inView && !read) {
      markMessageAsSeen(id);
    }
  }, [inView, id, read]);

  return (
    <div
      ref={ref}
      className="common-for-msg w-full h-auto flex items-center justify-center"
    >
      <div className="for-msg py-[0.5em] flex items-start justify-start border-b-[1.5px] border-[#E6E6E6] w-[95%] h-auto">
        <div className="picture-marker w-[auto] h-auto flex items-center justify-start">
          {!read && (
            <div className="marker w-[7px] h-[7px] rounded-full bg-blue-600"></div>
          )}

          <div className="picture flex items-center justify-center ml-[7px] w-[43px] h-[43px] rounded-full bg-gray-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-[23px] h-[23px]"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
          </div>
        </div>
        {/* BODY */}
        <div className="body-msg ml-[1em] flex items-start justify-start flex-col w-auto">
          <div className="message-body mb-[4px] text-start">
            <p className="text-[14px] text-gray-700 font-[400]">{message}</p>
          </div>
          <div className="date flex items-center justify-start">
            <label className="text-[13px] text-gray-500 font-[400]">
              Le <TimeStamp displayTime={true} timestamp={date} />
            </label>
            <span className="text-[13px] mx-[2px] text-gray-500 font-[400]">
              ⸱
            </span>
            <label className="text-[13px] text-gray-500 font-[400]">
              Système
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Message;
