import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  collection,
  query,
  where,
  onSnapshot,
  doc,
  setDoc,
  updateDoc,
  arrayUnion,
  DocumentData,
  deleteDoc,
  Timestamp,
} from "firebase/firestore";
import { database } from "../Firebase/databaseConfig";
import { v4 as uuid } from "uuid";

const exist = (array: any[], value: string) => {
  return array.some((obj: any) => obj.id === value && obj.role === "Manager");
};

type member = {
  id: string;
  name: string;
  picture: string;
  nickname: string;
  email: string;
  role: string;
};

const auth = getAuth();

export const getUserRole = (
  setUserRole: React.Dispatch<React.SetStateAction<string>>,
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>
) => {
  onAuthStateChanged(auth, (user) => {
    if (user) {
      setIsLoading && setIsLoading(true);
      let id = user.uid;

      const q = query(
        collection(database, "users"),
        where("id", "==", `${id}`)
      );

      onSnapshot(q, async (querySnapshot) => {
        querySnapshot.forEach((res) => {
          let user = res.data();

          const q = query(
            collection(database, "organisations"),
            where("id", "==", `${user.organisationId}`)
          );

          onSnapshot(q, async (querySnapshot) => {
            querySnapshot.forEach((obj) => {
              let organisation = obj.data();

              let creator: string = organisation.creator;
              let members: member[] = organisation.members;

              if (id === creator) {
                setUserRole("creator");
              } else if (exist(members, id)) {
                setUserRole("manager");
              } else if (members.some((item) => item.id === id)) {
                setUserRole("member");
              }
            });
            setIsLoading && setIsLoading(false);
          });
        });
      });
    }
  });
};

export const getUserRoleInProject = (
  projectId: string,
  setRole: React.Dispatch<React.SetStateAction<string>>
) => {
  onAuthStateChanged(auth, (user) => {
    if (user) {
      let id = user.uid;

      const q = query(
        collection(database, "projects"),
        where("id", "==", `${projectId}`)
      );

      onSnapshot(q, async (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let project = doc.data();
          let members = project.members;

          if (project.creator.id === id) {
            setRole("Manager");
          } else {
            for (const member of members) {
              if (member.id === id) {
                setRole(member.role);
              }
            }
          }
        });
      });
    }
  });
};

export const getUserRoleInTeam = (
  teamId: string,
  setRole: React.Dispatch<React.SetStateAction<string>>
) => {
  onAuthStateChanged(auth, (user) => {
    if (user) {
      let id = user.uid;

      const q = query(
        collection(database, "teams"),
        where("id", "==", `${teamId}`)
      );

      onSnapshot(q, async (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let team = doc.data();
          let members = team.members;

          for (const member of members) {
            if (member.id === id) {
              setRole(member.role);
            }
          }
        });
      });
    }
  });
};
