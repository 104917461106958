import React, { useState, useEffect } from "react";
import BoxLoad from "./BoxLoad";

function SectionLoad() {
  return (
    <div className="task-section flex items-center justify-center h-auto snap-center min-w-[97.5%] md:min-w-0 md:w-[24%] mx-[0.5em] md:mr-[1em]">
      <div className="for-task-section flex items-start justify-start flex-col w-full h-auto">
        {/* NAME */}
        <div className="task-section-name animate-pulse custom-shadow top-0 z-auto rounded-[8px] border-[#dadae0] bg-[#F6F6F6] flex items-center justify-center w-full h-[40px]">
          <div className="flex items-center justify-between w-[92.5%] h-auto">
            <div className="flex items-center justify-start w-auto h-auto">
              <label className="text-[15px] font-[500] text-[#333]"></label>
              <div
                className={`task-section-name-popup-} flex items-center p-0 justify-center ml-[8px] w-[20px] h-[20px] rounded-[50%]`}
              >
                <label className="text-[13px] m-0 text-white"></label>
              </div>
            </div>

            <div className="flex items-center justify-center hover:cursor-pointer z-[1]"></div>
          </div>
        </div>
        {/* BUTTON */}
        <div className="create-new-task flex items-center justify-center w-full h-auto mt-[7.5px]">
          <button className="text-[#333] animate-pulse flex items-center justify-center w-[100%] h-[40px] rounded-[8px] border-[1px] border-dashed border-[#dadae0] bg-[#F6F6F6]">
            <label className="text-[14px] font-[500] mr-[7px] hover:cursor-pointer">
              Ajouter une tache
            </label>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-[15px] h-[15px]"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 6v12m6-6H6"
              />
            </svg>
          </button>
        </div>

        {/* ALL TASKS */}
        <section className="all-tasks flex items-start justify-start flex-col w-full h-auto mt-[7.5px]">
          <BoxLoad />
          <BoxLoad />
          <BoxLoad />
          <BoxLoad />
          <BoxLoad />
          <BoxLoad />
        </section>
      </div>
    </div>
  );
}

export default SectionLoad;
