import { Body } from "@react-email/body";
import { Container } from "@react-email/container";
import { Head } from "@react-email/head";
import { Html } from "@react-email/html";
import { Img } from "@react-email/img";
import { Preview } from "@react-email/preview";
import { Section } from "@react-email/section";
import { Text } from "@react-email/text";

interface GithubAccessTokenEmailProps {
  reason: string;
  subject: string;
  username: string;
  teamName?: string;
  taskName?: string;
  projectName?: string;
  organisationName?: string;
}

export const GithubAccessTokenEmail = ({
  username,
  reason,
  subject,
  teamName,
  projectName,
  taskName,
  organisationName,
}: GithubAccessTokenEmailProps) => {
  return (
    <Html>
      <Head />
      <Preview>{subject}</Preview>
      <Body style={main}>
        <Container style={container}>
          <Img src="teamask.jpg" width="32" height="32" alt="Intellifrica" />

          <Text style={title}>
            <strong>@{username}</strong>
          </Text>

          <Section style={section}>
            <Text style={text}>
              Bonjour <strong>{username}</strong>!
            </Text>
            <Text style={text}>
              {reason === "org-created" &&
                `Félicitations! Vous venez de créer votre organisation "${organisationName}".`}
              {reason === "added-to-org" &&
                `Vous avez rejoins une organisation."`}
              {reason === "added-to-project" &&
                `Vous avez été ajouté à un nouveau projet nommé "${projectName}".`}
              {reason === "added-to-team" &&
                `Vous avez été ajouté à une nouvelle équipe nommée "${teamName}".`}
              {reason === "added-to-task" &&
                `Vous avez été ajouté à une nouvelle tache nommée "${taskName}".`}
              {reason === "added-proof" &&
                `Un nouveau justificatif a été ajouté par "${username}" pour le projet nommé "${projectName}" au niveau de la tache "${taskName}".`}
              {reason === "proof-deleted" &&
                `Votre justificatif pour la tache "${taskName}" du projet "${projectName}" a été supprimé.`}
              {reason === "promoted-manager-team" &&
                `Vous avez été promu au rang de Manager dans l'équipe nommée "${teamName}".`}
              {reason === "promoted-manager-org" &&
                `Félicitaions! Vous etes maintenant administrateur dans l'organisation nommée "${projectName}".`}{" "}
              Rendez-vous sur la plateforme pour voir cela.
            </Text>
          </Section>

          <Text style={footer}>
            Intellifrica, Inc.・Casablanca, Maroc・Ouagadougou, Burkina Faso
          </Text>
        </Container>
      </Body>
    </Html>
  );
};

export default GithubAccessTokenEmail;

const main = {
  backgroundColor: "#ffffff",
  color: "#24292e",
  fontFamily:
    '-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"',
};

const container = {
  width: "480px",
  margin: "0 auto",
  padding: "20px 0 48px",
};

const title = {
  fontSize: "24px",
  lineHeight: 1.25,
};

const section = {
  padding: "24px",
  border: "solid 1px #dedede",
  borderRadius: "5px",
  textAlign: "center" as const,
};

const text = {
  margin: "0 0 10px 0",
  textAlign: "left" as const,
};

const footer = {
  color: "#6a737d",
  fontSize: "12px",
  textAlign: "center" as const,
  marginTop: "60px",
};
