import React from "react";
import "../../index.css";
import { Timestamp, DocumentData } from "firebase/firestore";
import { deleteMessage } from "../../Backend/Chat/handleChat";

type deletedFormat = {
  isDeleted: boolean;
  message: string;
};

interface Props {
  id: string;
  date: string;
  type: string;
  author: string;
  admin: boolean;
  userId: string;
  message: string;
  section?: string;
  task: DocumentData;
  timeStamp: Timestamp;
  project: DocumentData;
  deleted: deletedFormat;
}

function Msg({
  id,
  date,
  type,
  author,
  message,
  timeStamp,
  project,
  deleted,
  userId,
  task,
  admin,
  section,
}: Props) {
  const deleteM = () => {
    deleteMessage(userId, project, task, id);
  };

  return (
    <div
      className={`common-for-msg mb-[1em] w-full h-auto flex ${
        author === "Vous" ? "items-end" : "items-start"
      }  justify-start flex-col`}
    >
      <label className="text-[11px] flex items-center text-gray-400 font-[400] w-auto">
        {author}
        <span className="mx-[3px]">·</span>
        <span>{date}</span>
        <span className="mx-[3px]">·</span>
        {author === "Vous" && (
          <button
            onClick={deleteM}
            disabled={
              deleted.isDeleted || (section === "Justificatifs" && !admin)
            }
            className="text-red-500 flex items-center justify-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-[12px] h-[12px]"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
              />
            </svg>
          </button>
        )}
      </label>

      {author === "Vous" ? (
        <div className="w-auto max-w-[85%] h-auto px-[8px] py-[6px] mt-[3px] text-start whitespace-normal border-[1.75px] border-[#928af9] bg-[#928af9] flex items-start justify-start rounded-[15px] rounded-tr-none text-white">
          {deleted.isDeleted ? (
            <label className="text-[14px] italic">{deleted.message}</label>
          ) : (
            <label className="text-[14px]">{message}</label>
          )}
        </div>
      ) : (
        <div className="w-auto max-w-[85%] h-auto px-[8px] py-[6px] mt-[3px] text-start whitespace-normal border-[1.5px] border-[#f2f6f9] bg-[#f2f6f9] flex items-start justify-start rounded-[15px] rounded-tl-none text-[#333]">
          {deleted.isDeleted ? (
            <label className="text-[14px] italic">{deleted.message}</label>
          ) : (
            <label className="text-[14px]">{message}</label>
          )}
        </div>
      )}
    </div>
  );
}

export default Msg;
